import {
    type BaseSyntheticEvent,
    type Dispatch,
    type ForwardRefExoticComponent,
    type ForwardedRef,
    type KeyboardEvent,
    type MouseEvent,
    type PropsWithoutRef,
    type ReactElement,
    type RefAttributes,
    type RefObject,
    type SetStateAction,
    forwardRef,
    useState
} from "react"

import classNames from "classnames"

import { type TUseManualHover, useManualHover } from "@/3514/hooks"
import { emptyCallback } from "@/shared/types/functions"

import { checkboxConfig as config } from "./checkbox.config"
import { ECheckboxVariant, type TCheckboxRef } from "./checkbox.types"

const { colorMap } = config

type TProps = {
    variant?: ECheckboxVariant
    onChange?(isChecked: boolean): void
    checked?: boolean
    isDisabled?: boolean
    ariaLabel?: string
}

const Component: ForwardRefExoticComponent<PropsWithoutRef<TProps> & RefAttributes<HTMLDivElement>> = forwardRef<
    TCheckboxRef,
    TProps
>(
    (
        {
            variant = ECheckboxVariant.Default,
            checked = false,
            isDisabled = false,
            onChange = emptyCallback,
            ariaLabel = String()
        }: TProps,
        ref: ForwardedRef<TCheckboxRef>
    ): ReactElement => {
        const {
            onTouchStart,
            onTouchEnd,
            onMouseLeave,
            onMouseEnter,
            onBlur,
            onFocus,
            isActionAffected
        }: TUseManualHover = useManualHover()

        const [isChecked, setIsChecked]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(
            checked || (ref as RefObject<TCheckboxRef>)?.current?.isChecked
        )

        const { wrapper: wrapperClasses, indicator: indicatorClasses } = colorMap[variant](
            isActionAffected,
            isChecked,
            isDisabled
        )

        const toggleCheckbox: (e: BaseSyntheticEvent) => void = (e: MouseEvent): void => (
            setIsChecked(!isChecked),
            ((ref as RefObject<TCheckboxRef>).current.isChecked = !isChecked),
            e.type === "click" && (ref as RefObject<TCheckboxRef>).current.blur(),
            onChange?.(!isChecked)
        )

        const handleAction: (e: BaseSyntheticEvent) => void = (e: KeyboardEvent & MouseEvent): void =>
            (e.key === "Enter" || e.type === "click") && toggleCheckbox(e)

        return (
            <div
                ref={ref}
                tabIndex={isDisabled ? -1 : 0}
                role="checkbox"
                aria-label={ariaLabel}
                aria-checked={isChecked}
                className={classNames(wrapperClasses)}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={handleAction}
                onKeyDown={handleAction}
            >
                <i className={classNames(indicatorClasses)} />
            </div>
        )
    }
)

Component.displayName = "Checkbox"

export { Component as Checkbox, type TProps as TCheckboxProps }
