import { startTransition, useLayoutEffect } from "react"

import { type NavigateFunction, useNavigate, useParams } from "react-router-dom"

import useMediaQuery from "$/hooks/use-media-query"

import { dashboardURL } from "@/components/onboarding/constants"
import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { useAuthContext } from "@/context"
import { SimulabContextProvider, SimulabHeader } from "@/entities/simulab"
import { SimulabCareLink } from "@/features/simulab-chat"
import { ParticipantLayout } from "@/layouts"
import { LeftSidebar } from "@/shared/participant-left-sidebar"
import { Simulab } from "@/widgets/simulab"

import "./styles.scss"

export const SimulabChatPage = () => {
    const { user } = useAuthContext()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const params = useParams()

    const navigate: NavigateFunction = useNavigate()

    useLayoutEffect(
        (): void => (!user.practiceLab && startTransition((): void => navigate(dashboardURL)), void 0),
        [user, navigate]
    )

    return (
        <SimulabContextProvider conversationId={params.conversationId}>
            <ParticipantLayout
                className="simulab-layout"
                noPadding
                showLogoOnMobile={false}
                leftSidebar={<LeftSidebar integrated />}
                title={
                    !isMobile && (
                        <SimulabHeader showCoacheesLink withFeedbackReport withTooltip statusTagVariant="outlined" />
                    )
                }
                header={isMobile && <SimulabHeader withFeedbackReport withTooltip statusTagVariant="outlined" />}
            >
                <Simulab />
                <SimulabCareLink />
            </ParticipantLayout>
        </SimulabContextProvider>
    )
}
