import { type ChangeEvent, type MutableRefObject, useCallback, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import useMediaQuery from "$/hooks/use-media-query"
import { usePageRefreshed } from "$/hooks/use-page-refreshed"
import useUpdateEffect from "$/hooks/use-update-effect"
import { isEmptyString } from "$/utils/gates"

import {
    Button,
    EButtonSize,
    EButtonVariant,
    ETextAreaScrollbarVariant,
    ETypographyColor,
    ETypographySize,
    TextArea
} from "@/3514/components"
import { MAX_WIDTH_MOBILE_MEDIA, Role } from "@/constants"
import { useSimulabContext } from "@/entities/simulab"
import { SimulabActionContextType, SimulabContextType } from "@/entities/simulab/context/SimulabContextProvider"
import { DisabledSendIconSvg } from "@/svgs/DisabledSendIconSvg"
import { MessageSendIconSvg } from "@/svgs/MessageSendSvg"

import { SIMULAB_INPUT_CHARACTER_LIMIT, SIMULAB_WARNING_CHARACTER_NUMBER } from "../constants"

import { SimulabInputHeader } from "./SimulabInputHeader"

import "./styles.scss"

export const SimulabChatInputBox = () => {
    const { t } = useTranslation()
    const inputRef = useRef<HTMLDivElement>()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const {
        conversation,
        sendMessage,
        simulabMessages,
        badMessage,
        hasError,
        conversationStarted,
        unselectMessage,
        resetErrors,
        selectMessage,
        onError
    }: SimulabContextType & SimulabActionContextType = useSimulabContext()

    const { handleSubmit, watch, setValue, setFocus } = useForm()

    const [lastMessageValue, setLastMessageValue] = useState("")
    const [isFocused, setIsFocused] = useState(false)

    const isRefreshedPage = usePageRefreshed()

    useUpdateEffect(() => {
        if (badMessage || hasError) {
            setValue("simulab_mesage_text", lastMessageValue)
        }
    }, [badMessage, hasError, lastMessageValue])

    const currentViewStage = useMemo(() => {
        return conversation?.stages?.find(stage => stage.current)
    }, [conversation?.stages])

    const messageTextPrompt = watch("simulab_mesage_text")

    const isMoreThanWarningLimit = messageTextPrompt?.length > SIMULAB_WARNING_CHARACTER_NUMBER

    const disabledSendBtn =
        (simulabMessages?.messages?.at(-1)?.authorRole === Role.Participant && !isRefreshedPage && !hasError) ||
        isEmptyString(messageTextPrompt)

    const onFocus = useCallback(() => {
        setIsFocused(true)
    }, [])

    const onBlur = useCallback(() => {
        setIsFocused(false)
    }, [])

    const textareaRef: MutableRefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>()

    const onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>): void => (
            setValue("simulab_mesage_text", e.target.value, { shouldValidate: true }),
            (textareaRef.current.value = e.target.value),
            void 0
        ),
        [setValue]
    )

    const setFocusToInput = useCallback(
        event => {
            event.stopPropagation()
            setFocus("simulab_mesage_text")
            onFocus()
        },
        [setFocus]
    )

    const onSendMessage = handleSubmit(values => {
        const message = values.simulab_mesage_text
        selectMessage(null)
        unselectMessage(false)
        setLastMessageValue(message)
        sendMessage.mutate(
            { message, stage: currentViewStage?.id },
            {
                onSuccess() {
                    setValue("simulab_mesage_text", "")
                    textareaRef.current.value = String()
                    resetErrors()
                },
                onError(error) {
                    onError(error)
                }
            }
        )
    })

    const onKeyDown = e => {
        if (isMobile) {
            inputRef.current?.scrollIntoView()
        }
        if (e.key === "Enter" && !disabledSendBtn) {
            if (!e.shiftKey) {
                onSendMessage()
            } else {
                setValue("simulab_mesage_text", `${messageTextPrompt}\n`)
            }
        }
    }

    return (
        <section className="simulab-chat-input-wrapper">
            <div className={classNames("simulab-chat-input-box", { focused: isFocused })}>
                <SimulabInputHeader currentViewStage={currentViewStage} />
                <form
                    onSubmit={onSendMessage}
                    className={classNames("d-flex flex-column simulab-chat-input-form", {
                        "not-allowed": !conversationStarted
                    })}
                    data-testid="simulab-chat-input-form"
                    onClick={setFocusToInput}
                >
                    <div ref={inputRef}>
                        <TextArea
                            ref={textareaRef}
                            name="simulab_mesage_text"
                            textColor={ETypographyColor.Black}
                            textSize={ETypographySize.Medium}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={onChange}
                            isDisabled={!conversationStarted}
                            borderRadius={{ tl: 0, tr: 0, br: 0, bl: 0 }}
                            scrollbarVariant={ETextAreaScrollbarVariant.Transparent}
                            withDynamicHeight
                            withNoResize
                            withNoPaddings
                            withItalicPlaceholder
                            withNoBorder
                            withTransparentBorder
                            placeholder={t("common.inputPlaceholder")}
                        />
                    </div>
                    <div className="d-flex align-self-end">
                        {isMoreThanWarningLimit && (
                            <p className="max-character-counter">
                                {t("Max.")} {messageTextPrompt?.length}/{SIMULAB_INPUT_CHARACTER_LIMIT}
                            </p>
                        )}
                        <Button
                            aria-label="send message"
                            variant={EButtonVariant.EmptyTransparent}
                            size={EButtonSize.Container}
                            className={classNames("simulab-chat-input-box__send-btn p-0 ml-2", {
                                disabled: disabledSendBtn
                            })}
                            isDisabled={disabledSendBtn}
                        >
                            {disabledSendBtn ? <DisabledSendIconSvg /> : <MessageSendIconSvg />}
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    )
}
