import type { FC, ReactElement } from "react"

import classnames from "classnames"

type TProps = { className?: string }

const Image: FC<TProps> = ({ className = String() }: TProps): ReactElement => (
    <svg
        className={classnames({ [className]: className })}
        width="103"
        height="125"
        viewBox="0 0 103 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M87.627 50.066L66.657 47.9274C60.1078 47.2596 54.2571 52.0273 53.5892 58.5766C53.5042 59.4104 53.5074 60.251 53.5987 61.0842L55.2041 75.7363C75.4702 86.2539 87.333 91.6859 90.7925 92.0323C92.4132 92.1946 94.0448 92.3346 95.6872 92.4524C97.8501 92.6074 99.7292 90.9797 99.8842 88.8168C99.918 88.3454 99.8664 87.8717 99.7319 87.4186L88.9732 51.1814C88.792 50.5711 88.2603 50.1305 87.627 50.066Z"
            fill="#DA4200"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3691 50.066L36.3391 47.9274C42.8883 47.2596 48.739 52.0273 49.4069 58.5766C49.4919 59.4104 49.4887 60.251 49.3974 61.0842L47.792 75.7363C27.5259 86.2539 15.6631 91.6859 12.2036 92.0323C10.5829 92.1946 8.95134 92.3346 7.30889 92.4524C5.14599 92.6074 3.26693 90.9797 3.11188 88.8168C3.07809 88.3454 3.12969 87.8717 3.26422 87.4186L14.0229 51.1814C14.2041 50.5711 14.7358 50.1305 15.3691 50.066Z"
            fill="#DA4200"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6094 106.875L38.457 123.384C38.7466 124.202 39.6442 124.63 40.4618 124.34C40.9084 124.182 41.2597 123.831 41.4178 123.384L43.6015 117.219L45.4335 112.047L46.3495 109.461L39.9374 106.875H32.6094Z"
            fill="#FEA57F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.0859 109.461L58.0019 112.047L59.834 117.219L62.0176 123.384C62.3072 124.202 63.2048 124.63 64.0224 124.34C64.4689 124.182 64.8202 123.831 64.9784 123.384L70.8261 106.875H63.498L57.0859 109.461Z"
            fill="#FEA57F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8196 0.179285L39.2148 10.562C47.1043 13.5522 55.8154 13.5522 63.7049 10.562L91.2356 0.127951C92.2494 -0.256297 93.3828 0.254098 93.7671 1.26795C93.9425 1.7308 93.9366 2.24282 93.7506 2.70151L92.2796 6.32848C90.4771 10.7729 89.5504 15.5238 89.5504 20.3198V27.5817V56.6291V76.8298C89.5504 97.7582 72.5846 114.724 51.6562 114.724C30.7278 114.724 13.762 97.7582 13.762 76.8298V56.6291V27.5817V20.3198C13.762 15.5294 12.7808 10.7897 10.8788 6.39302L9.32203 2.79448C8.89154 1.79938 9.34926 0.643717 10.3444 0.213235C10.813 0.0105145 11.3421 -0.00166323 11.8196 0.179285Z"
            fill="#FE4D00"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.1656 45.4872C61.9231 45.4872 56.8625 40.4259 56.8625 34.1826C56.8625 28.9994 60.2382 24.1467 65.1069 23.2968C67.5061 22.878 68.7026 25.9285 71.2695 27.9138C74.4772 30.3947 79.4688 30.7501 79.4688 34.1826C79.4688 40.4259 74.4082 45.4872 68.1656 45.4872Z"
            fill="#E5F7FF"
        />
        <path
            opacity="0.9"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.3787 40.5675C65.2094 40.5675 62.6402 37.9979 62.6402 34.8282C62.6402 32.6504 63.8282 30.7074 65.64 29.7835C67.002 29.089 68.2728 29.7847 70.019 31.0724C71.6488 32.2743 74.1172 31.6585 74.1172 34.8282C74.1172 37.9979 71.548 40.5675 68.3787 40.5675Z"
            fill="#0404CB"
        />
        <path
            d="M70.6061 33.9066C71.5665 33.9066 72.3451 33.1279 72.3451 32.1674C72.3451 31.2069 71.5665 30.4282 70.6061 30.4282C69.6457 30.4282 68.8672 31.2069 68.8672 32.1674C68.8672 33.1279 69.6457 33.9066 70.6061 33.9066Z"
            fill="#E5F7FF"
        />
        <path
            d="M71.2508 36.3515C71.7842 36.3515 72.2165 35.9191 72.2165 35.3857C72.2165 34.8523 71.7842 34.4199 71.2508 34.4199C70.7175 34.4199 70.2852 34.8523 70.2852 35.3857C70.2852 35.9191 70.7175 36.3515 71.2508 36.3515Z"
            fill="#E5F7FF"
        />
        <path
            d="M74.658 40.9852C72.1282 42.8643 71.2058 45.0788 72.8359 45.0957C74.4659 45.1126 76.3971 44.8257 77.628 43.8941C78.3024 43.3836 78.7666 42.6794 78.8557 41.7254C79.141 38.6708 77.1879 39.1061 74.658 40.9852Z"
            fill="#66CCFF"
        />
        <path
            d="M75.3259 52.4733C74.0459 51.17 76.2142 49.9353 76.3761 47.73C76.6009 44.6687 78.3246 49.8701 78.2312 51.143C78.1017 52.907 76.606 53.7765 75.3259 52.4733Z"
            fill="#66CCFF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.0141 45.4872C42.2566 45.4872 47.3172 40.4259 47.3172 34.1826C47.3172 28.9994 43.9415 24.1467 39.0728 23.2968C36.6736 22.878 35.4771 25.9285 32.9102 27.9138C29.7025 30.3947 24.7109 30.7501 24.7109 34.1826C24.7109 40.4259 29.7715 45.4872 36.0141 45.4872Z"
            fill="#E5F7FF"
        />
        <path
            opacity="0.9"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.2307 40.5675C39.4 40.5675 41.9692 37.9979 41.9692 34.8282C41.9692 32.6504 40.7812 30.7074 38.9694 29.7835C37.6073 29.089 36.3366 29.7847 34.5904 31.0724C32.9606 32.2743 30.4922 31.6585 30.4922 34.8282C30.4922 37.9979 33.0614 40.5675 36.2307 40.5675Z"
            fill="#0404CB"
        />
        <path
            d="M33.6218 33.9066C34.5821 33.9066 35.3607 33.1279 35.3607 32.1674C35.3607 31.2069 34.5821 30.4282 33.6218 30.4282C32.6614 30.4282 31.8828 31.2069 31.8828 32.1674C31.8828 33.1279 32.6614 33.9066 33.6218 33.9066Z"
            fill="#E5F7FF"
        />
        <path
            d="M33.2977 36.6767C33.831 36.6767 34.2634 36.2443 34.2634 35.7109C34.2634 35.1775 33.831 34.7451 33.2977 34.7451C32.7644 34.7451 32.332 35.1775 32.332 35.7109C32.332 36.2443 32.7644 36.6767 33.2977 36.6767Z"
            fill="#E5F7FF"
        />
        <path
            d="M30.4549 39.3798C33.6572 41.7584 36.6247 45.98 32.262 46.0245C29.0682 46.0571 24.9607 45.17 24.642 41.7584C23.9846 34.719 27.2525 37.0013 30.4549 39.3798Z"
            fill="#66CCFF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.6324 45.3541C48.6426 45.3381 48.6528 45.3219 48.663 45.3055C49.7138 43.6213 51.9309 43.1078 53.615 44.1587C54.0959 44.4588 54.4991 44.8684 54.7915 45.3541C55.3181 46.2286 56.1432 47.0881 57.2669 47.9327C57.5646 48.1564 57.6246 48.5792 57.4008 48.8769C57.3798 48.9048 57.3567 48.9311 57.3316 48.9555L56.4204 49.8406C55.431 50.8018 54.5699 51.8868 53.8585 53.0688L52.7676 54.8815C52.422 55.4558 51.6762 55.6412 51.1018 55.2955C50.9321 55.1934 50.79 55.0512 50.6878 54.8815L49.5969 53.0688C48.8856 51.8868 48.0245 50.8018 47.035 49.8406L46.1459 48.9769C45.8788 48.7174 45.8726 48.2905 46.132 48.0233C46.159 47.9956 46.1883 47.9702 46.2196 47.9474C47.1651 47.2612 47.9694 46.3967 48.6324 45.3541Z"
            fill="#FFCAB3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.8618 65.2656H52.4492C64.2642 65.2656 73.8422 74.8436 73.8422 86.6587C73.8422 98.4737 64.2642 108.052 52.4492 108.052H50.8618C39.0467 108.052 29.4688 98.4737 29.4688 86.6587C29.4688 74.8436 39.0467 65.2656 50.8618 65.2656Z"
            fill="#FEA57F"
            stroke="#FEA57F"
            strokeWidth="3.14106"
        />
        <path
            d="M39.2852 90.6248L44.5928 95.4973C45.0734 95.9384 45.8116 95.9384 46.2921 95.4973L50.7501 91.4048C51.2307 90.9636 51.9689 90.9636 52.4495 91.4048L56.9074 95.4973C57.388 95.9384 58.1262 95.9384 58.6068 95.4973L63.9144 90.6248"
            stroke="#FE4D00"
            strokeWidth="3.14106"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M39.2852 80.0271L44.5928 84.8996C45.0734 85.3408 45.8116 85.3408 46.2921 84.8996L50.7501 80.8071C51.2307 80.366 51.9689 80.366 52.4495 80.8071L56.9074 84.8996C57.388 85.3408 58.1262 85.3408 58.6068 84.8996L63.9144 80.0271"
            stroke="#FE4D00"
            strokeWidth="3.14106"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

Image.displayName = "OwlSadImage"

export { Image as OwlSadImage }
