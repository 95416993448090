import { type ComponentProps, type FC, type ReactElement, type ReactNode, isValidElement } from "react"

import classNames from "classnames"
import { Link as RouterLink } from "react-router-dom"

import { getTransitionTwClasses } from "@/3514/utils"

import { ETypographyColor, ETypographyFontWeight, ETypographySize, ETypographyTag, Typography } from "../typography"

type TProps = ComponentProps<typeof RouterLink> & {
    children: string | ReactNode
    className?: string
    isTargetBlank?: boolean
    textSize?: ETypographySize
    textWeight?: ETypographyFontWeight
}

const classes: { link: string; content: string } = {
    link: "group focus:!outline-[0px]",
    content: classNames(
        [
            "decoration-accent",
            "underline",
            "decoration-[1px]",
            "underline-offset-[3px]",
            "group-focus:underline-offset-[5px]",
            "group-hover:underline-offset-[5px]"
        ],
        getTransitionTwClasses()
    )
}

const Component: FC<TProps> = ({
    to,
    children,
    isTargetBlank = false,
    className = String(),
    textSize = ETypographySize.Small,
    textWeight = ETypographyFontWeight.Bold,
    ...props
}: TProps): ReactElement => (
    <RouterLink
        to={to}
        {...(isTargetBlank && { target: "_blank" })}
        {...props}
        className={classNames(classes.link, { [className]: className })}
    >
        {isValidElement(children) ? (
            children
        ) : (
            <Typography
                tag={ETypographyTag.Span}
                color={ETypographyColor.Accent}
                className={classes.content}
                weight={textWeight}
                size={textSize}
                text={children}
            />
        )}
    </RouterLink>
)

Component.displayName = "Link"

export { Component as Link }
