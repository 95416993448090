import { type FC, type LazyExoticComponent, lazy } from "react"

const ParticipantDashboardPage: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./participant-dashboard").then(({ ParticipantDashboardPage: D }): { default: FC } => ({
            default: D
        }))
)
const ParticipantChapterPage: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./participant-chapter").then(({ ParticipantChapterPage: C }): { default: FC } => ({
            default: C
        }))
)

export { ParticipantDashboardPage, ParticipantChapterPage }
