import type { FC, ReactElement } from "react"

import classNames from "classnames"

type TProps = { className?: string; isDisabled?: boolean }

const Icon: FC<TProps> = ({ className = String(), isDisabled = false }: TProps): ReactElement => (
    <svg
        className={classNames({ [className]: className })}
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M1 1.5L8 8.5L15 1.5" stroke={isDisabled ? "#525252" : "#0404CB"} strokeWidth="2.5" />
    </svg>
)

Icon.displayName = "ChevronIcon"

export { Icon as ChevronIcon }
