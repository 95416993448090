import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import Coach, { CoachApi } from "@/models/coach"
import { isEmptyObject } from "@/utils/common"

const getCoach = async () => {
    const { data } = await http.get<{ coach_data: CoachApi }>(Urls.coach())
    return data.coach_data
}

type TOptions = { enabled?: boolean; staleTime?: number }

export default function useCoach(options: TOptions = { enabled: true }) {
    return useQuery([ServerStateKeys.Coach], getCoach, {
        select: useCallback(data => {
            return isEmptyObject(data) ? null : new Coach(data)
        }, []),
        enabled: options.enabled,
        ...(options.staleTime && { staleTime: options.staleTime })
    })
}
