import { FC, useRef, useState } from "react"

import sanitizeHtml from "sanitize-html"

import { Button, EButtonVariant } from "@/3514/components"
import Chevron from "@/svgs/Chevron"

interface IProps {
    version: string
    content: string
}

const Accordion: FC<IProps> = ({ version, content }) => {
    const [isActive, setIsActive] = useState(false)
    const contentRef = useRef<HTMLDivElement>()

    const toggleAccordion = () => {
        setIsActive(prev => !prev)
    }

    return (
        <Button
            type="button"
            variant={EButtonVariant.EmptyTransparent}
            className="accordion-item"
            onClick={toggleAccordion}
        >
            <div className="d-flex flex-col mx-auto align-items-center gap-y-[10px]">
                <div className="d-flex mx-auto w-min items-center gap-x-[5px]">
                    <span className="accordion-item-text">{version}</span>{" "}
                    <Chevron isActive={isActive} className="accordion-item-chevron" />
                </div>
                <div
                    className="accordion-item-content"
                    ref={contentRef}
                    style={isActive ? { height: `${contentRef.current?.scrollHeight}px` } : { height: "0px" }}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }}
                />
            </div>
        </Button>
    )
}

export default Accordion
