import { ReactNode } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import Heading from "$/components/Heading/Heading"

import CareTeamLink from "@/shared/CareTeamLink"

import "./styles.scss"

type Props = {
    text: ReactNode
    headerText?: ReactNode
    withCareLink?: boolean
    variant?: "primary" | "secondary"
    noMargin?: boolean
}

const ModuleUnassignedStatus = ({
    text,
    headerText = "",
    withCareLink = true,
    variant = "primary",
    noMargin = false
}: Props) => {
    const { t } = useTranslation()

    return (
        <div className={classNames("module-unassigned-status", variant)}>
            <Heading tag="h4" textAlign="center" fontSize={16}>
                {headerText || t("This module is currently unassigned.")}
            </Heading>
            <p className={classNames("text-center", { "mb-0": noMargin })}>{text}</p>
            {withCareLink && (
                <CareTeamLink linkClassName="!text-accentOld" className="m-0 text-center" variant="brand" />
            )}
        </div>
    )
}

export default ModuleUnassignedStatus
