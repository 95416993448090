import { useMemo } from "react"

import classNames from "classnames"

import ActiveCheckMarkSvg from "@/svgs/ActiveCheckMarkSvg"
import AppleIcon from "@/svgs/AppleIconSvg"
import DisabledCrossMarkSvg from "@/svgs/DisabledCrossMarkSvg"
import GoogleIcon from "@/svgs/GoogleIcon"
import OutLookIcon from "@/svgs/OutLookIcon"
import PendingQuestionMarkSvg from "@/svgs/PendingQuestionMarkSvg"

import { CalendarStatus } from "../constants"
import { CalendarAccount } from "../models"

type Props = {
    calendar: CalendarAccount
}

const CalendarAccountItem = ({ calendar }: Props) => {
    const calendarIcons = useMemo(
        () => ({
            google: <GoogleIcon />,
            live_connect: <OutLookIcon />,
            apple: <AppleIcon />
        }),
        []
    )

    const statusIcons = useMemo(
        () => ({
            active: <ActiveCheckMarkSvg />,
            pending: <PendingQuestionMarkSvg />,
            disabled: <DisabledCrossMarkSvg />
        }),
        []
    )

    return (
        <div className="calendar-accounts__item" key={calendar.profileId}>
            <div className="flex-1 text-left flex items-center">
                <span className="mr-2">{calendarIcons[calendar.providerName]}</span>&nbsp;
                <span>{calendar.profileName}</span>
            </div>
            {calendar.status !== CalendarStatus.Pending && (
                <div className="d-flex align-items-center">
                    <span
                        className={classNames("mr-2 text-capitalize", {
                            "text-accentOld font-extrabold": calendar.status === CalendarStatus.Active,
                            "color-gray": calendar.status !== CalendarStatus.Active
                        })}
                    >
                        {calendar.status}
                    </span>
                    {statusIcons[calendar.status]}
                </div>
            )}
        </div>
    )
}

export default CalendarAccountItem
