import { FC, useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"

import Heading from "$/components/Heading/Heading"
import { HTTPStatus } from "$/constants"
import { isNullOrUndefined } from "$/utils/gates"
import { getErrorMessages } from "$/utils/get-error-messages"

import { Button } from "@/3514/components"
import "@/assets/onboarding.scss"
import { type IAuthContext, useAuthContext } from "@/context"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import LikertComponent from "@/shared/likert/LikertComponent"
import { isColoredLikertComponent, isFreeResponse, isLikertComponent } from "@/utils/gates/utilts"
import useNavigationOnboarding from "@/utils/hooks/use-navigation-onboarding"

import useNextStep from "./api/mutations/use-next-step"
import useReflectionObjective from "./api/use-reflection-objective"
import FreeResponseComponent from "./components/FreeResponseComponent"
import { StepsNavigator } from "./components/StepsNavigator"
import { WarningMessage } from "./components/WarningMessage"
import { OnboardingSteps } from "./constants"
import OnboardingLayout from "./layout/OnboardingLayout"

interface IProps {}

const ReflectionObjectiveStep: FC<IProps> = () => {
    const { t } = useTranslation()
    const { user }: IAuthContext = useAuthContext()
    const navigate = useNavigate()
    const [errorsMsg, setErrorsMsg] = useState(null)
    const [isBusy, setIsBusy] = useState(false)
    const { data, refetch } = useReflectionObjective()
    const { getNextStepUrl } = useNavigationOnboarding()
    const handleNextStep = useNextStep()

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    let answer_data = []

    const question_list = data?.reduce((acc, currentItem, currentIndex) => {
        acc[currentIndex] = [currentItem.question_data]
        const currentAnswer =
            { ...currentItem.answer_data, component_type: currentItem?.question_data?.component_type } || []
        answer_data = [...answer_data, ...[currentAnswer]]

        return acc.flat()
    }, [])

    const defaultValues = answer_data?.reduce((acc, currentItem) => {
        if (currentItem.question_uuid) {
            acc[currentItem.question_uuid] = isLikertComponent(currentItem?.component_type)
                ? currentItem?.option_uuid
                : currentItem?.answer
        }
        return acc
    }, {})

    const shapeWithDynamicFields = question_list
        ? question_list?.reduce((obj, question) => {
              obj[question.uuid] = question?.response_required
                  ? yup.string().required(t("Answer is required")).nullable(true)
                  : yup.string().notRequired().nullable(true)
              return obj
          }, {})
        : null

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(yup.object(shapeWithDynamicFields)),
        defaultValues: useMemo(() => {
            return defaultValues
        }, [data])
    })

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        reset(defaultValues)
    }, [data])

    const onSubmit = values => {
        const answer_list = getAnswerList(values)

        const data = {
            step: OnboardingSteps.REFLECTION_OBJECTIVE_STEP,
            step_data: [
                {
                    answer_list,
                    question_list,
                    type: "reflection_objective"
                }
            ]
        }

        submitData(data)
    }

    const getAnswerList = values => {
        return data
            .map(({ question_data: question }) => {
                const answer = values[question.uuid]
                return getComponentAnswer(question, answer)
            })
            .filter(answer => !isNullOrUndefined(answer))
    }

    const getComponentAnswer = (question, answer) => {
        if (isLikertComponent(question.component_type)) {
            const likertComponetAnswer = Object.create({
                question_uuid: question.uuid,
                option_uuid: answer,
                answer:
                    getValueFromTranslatedObject(question.options.find(option => option.uuid === answer)?.response) ||
                    answer ||
                    "",
                rank: question.order
            })

            if (isColoredLikertComponent(question.component_type)) {
                likertComponetAnswer.color_data = question.color_range
            }
            if (isNullOrUndefined(likertComponetAnswer.option_uuid)) {
                delete likertComponetAnswer.option_uuid
            }
            return likertComponetAnswer
        }

        if (isFreeResponse(question.component_type)) {
            const freeResponseComponentAnswer = {
                question_uuid: question.uuid,
                rank: question.order,
                answer: answer ?? ""
            }
            return freeResponseComponentAnswer
        }

        return null
    }

    const submitData = async data => {
        setIsBusy(true)
        try {
            await handleNextStep.mutateAsync(data)
            const nextStep = await getNextStepUrl(OnboardingSteps.REFLECTION_OBJECTIVE_STEP)
            navigate(nextStep.url)
        } catch (error) {
            const errorMessage = getErrorMessages(error)
            if (error?.response?.status === HTTPStatus.BAD_REQUEST) {
                setErrorsMsg(errorMessage)
            }
            console.log(errorMessage)
        } finally {
            setIsBusy(false)
        }
    }

    return (
        <OnboardingLayout
            header={<StepsNavigator activeStep={{ id: OnboardingSteps.REFLECTION_OBJECTIVE_STEP, order: 5 }} />}
        >
            <div className="container container-max-md">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-5 mt-6 text-center">
                        <Heading className="step5-title" textAlign="center">
                            {t("Self Assessment")}
                        </Heading>
                    </div>
                    <WarningMessage message={errorsMsg} />
                    {data?.map((item: any, indexQuestion) => (
                        <>
                            {isLikertComponent(item.question_data.component_type) && (
                                <LikertComponent
                                    className={classNames({ "mb-60": indexQuestion !== data?.length - 1 })}
                                    questionNumber={indexQuestion + 1}
                                    register={register}
                                    item={item.question_data}
                                    control={control}
                                    errors={errors}
                                    color="blank"
                                    rowClassName="onboarding-likert"
                                    questionStyle="one-line"
                                    showOddLabels
                                />
                            )}
                            {isFreeResponse(item.question_data.component_type) && (
                                <FreeResponseComponent
                                    questionNumber={indexQuestion + 1}
                                    register={register}
                                    item={item.question_data}
                                    errors={errors}
                                    questionStyle="one-line"
                                />
                            )}
                        </>
                    ))}
                    <div className="mt-50 mx-auto w-max">
                        <Button isBusy={isBusy}>{!user?.groupExists ? t("Next") : t("Finish")}</Button>
                    </div>
                </form>
            </div>
        </OnboardingLayout>
    )
}

export default ReflectionObjectiveStep
