import classNames from "classnames"

import { getTransitionTwClasses } from "@/3514/utils"

import { ETypographyColor, ETypographySize, ETypographyTag, type TTypographyProps } from "../typography"

import { EButtonSize, EButtonVariant } from "./button.types"

const baseClasses: string = classNames("flex items-center justify-center w-fit", getTransitionTwClasses("colors"))

const sizeMap: { [S in EButtonSize]: string } = {
    [EButtonSize.Medium]: "px-[25px] py-[8px] rounded-[30px]",
    [EButtonSize.Small]: "px-[12px] py-[0px] rounded-[30px]",
    [EButtonSize.Container]: "p-[5px] rounded-full"
}

const colorMap: { [V in EButtonVariant]: (isDisabled: boolean) => string } = {
    [EButtonVariant.Solid]: (isDisabled: boolean): string =>
        classNames(isDisabled ? "bg-gray-300" : "bg-blue-200 hover:bg-accent focus:bg-accent"),
    [EButtonVariant.Outlined]: (isDisabled: boolean): string =>
        classNames(
            "border-[1px] bg-white",
            isDisabled ? "border-gray-300" : "border-blue-200 hover:border-accent focus:border-accent"
        ),
    [EButtonVariant.EmptyTransparent]: (_isDisabled: boolean): string => "bg-transparent"
}

function getTypographyProps(
    btnSize: EButtonSize,
    btnVariant: EButtonVariant,
    isBtnDisabled: boolean
): Omit<TTypographyProps, "text"> {
    return {
        tag: ETypographyTag.Span,
        size: btnSize === EButtonSize.Medium ? ETypographySize.Medium : ETypographySize.Small,
        color:
            isBtnDisabled && btnVariant === EButtonVariant.Solid
                ? ETypographyColor.LightGray
                : isBtnDisabled && btnVariant === EButtonVariant.Outlined
                  ? ETypographyColor.DarkGray
                  : !isBtnDisabled && btnVariant === EButtonVariant.Solid
                    ? ETypographyColor.White
                    : !isBtnDisabled && btnVariant === EButtonVariant.Outlined && ETypographyColor.Dark
    }
}

const config = { colorMap, sizeMap, baseClasses, getTypographyProps }

export { config as buttonConfig }
