import { useMemo } from "react"

import classNames from "classnames"
import ContentLoader from "react-content-loader"
import { useTranslation } from "react-i18next"
import sanitizeHtml from "sanitize-html"

import { Button, EButtonSize, EButtonVariant } from "@/3514/components"
import { type IAuthContext, useAuthContext } from "@/context"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { isParticipant } from "@/utils/gates/user"

import QuestionCard from "../question-card/QuestionCard"
import SummaryResult from "../summary-result/SummaryResult"

type Props = {
    participantName?: string
    diagnosticSummary: any
    isLoading: boolean
    showQuestions?: boolean
    showFullDiagnostic?: boolean
    onFullDiagnosticeClick?: () => void
    onTooltipClick?: (category) => void
}

const DiagnosticSummaryQuestions = ({
    participantName = "",
    diagnosticSummary,
    isLoading,
    showQuestions = false,
    showFullDiagnostic = false,
    onFullDiagnosticeClick,
    onTooltipClick
}: Props) => {
    const { t } = useTranslation()

    const { user }: IAuthContext = useAuthContext()

    const isCoachSide: boolean = !isParticipant(user)

    const questions = useMemo(() => {
        if (!showQuestions) return

        if (!diagnosticSummary) return []
        return diagnosticSummary?.reflection_objective_question?.flatMap(item => {
            return item.answer_data.map(data => ({
                ...data,
                question: item.question_list.find(question => question.uuid === data.question_uuid)?.question ?? null
            }))
        })
    }, [diagnosticSummary])

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    if (isLoading) {
        return (
            <>
                {Array(3)
                    .fill("")
                    .map((_, index) => (
                        <div className="w-100 mb-20" data-testid="loader" style={{ height: 350 }} key={index}>
                            <ContentLoader
                                speed={3}
                                height={350}
                                width="100%"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
                                <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
                            </ContentLoader>
                        </div>
                    ))}
            </>
        )
    }

    const hasDiagnosticSummary = diagnosticSummary?.summary?.length > 0

    return (
        <>
            {hasDiagnosticSummary
                ? diagnosticSummary.summary.map(summaryItem => (
                      <SummaryResult
                          key={summaryItem.category.id}
                          summary={summaryItem}
                          className="mb-3"
                          showQuestionTooltip
                          onTooltipClick={onTooltipClick}
                          selectedUserFirstName={participantName}
                      />
                  ))
                : null}
            {hasDiagnosticSummary
                ? showFullDiagnostic && (
                      <Button
                          variant={EButtonVariant.EmptyTransparent}
                          size={EButtonSize.Container}
                          onClick={onFullDiagnosticeClick}
                      >
                          <span className={classNames(isCoachSide ? "text-accentOld" : "color-brand")}>
                              {t("View full diagnostic")}
                          </span>
                      </Button>
                  )
                : null}
            {showQuestions && (
                <div className="mt-4" data-testid="question-card-container">
                    {questions?.map(objective => (
                        <QuestionCard
                            key={objective.question_uuid}
                            header={
                                <span className="font-extrabold">
                                    {sanitizeHtml(getValueFromTranslatedObject(objective.question), {
                                        allowedTags: []
                                    })}
                                </span>
                            }
                            body={<span>{objective.answer}</span>}
                            className="mb-3"
                        />
                    ))}
                </div>
            )}
        </>
    )
}

export default DiagnosticSummaryQuestions
