import { type FC, type ReactElement } from "react"

import { Button } from "@/3514/components"
import { type IUseTranslation, useTranslation } from "@/hooks"

import type { THandleRefreshPageButtonClickFunction } from "../types"

type TCoachRelinkAccountsConfirmationBoxProps = {
    handleRefreshPage: THandleRefreshPageButtonClickFunction
    shouldRefreshButtonBeLoading: boolean
}

const CoachRelinkAccountsConfirmationBox: FC<TCoachRelinkAccountsConfirmationBoxProps> = ({
    handleRefreshPage,
    shouldRefreshButtonBeLoading
}: TCoachRelinkAccountsConfirmationBoxProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="coach-relink-accounts__confirmation-box">
            <p className="font-extrabold m-0 text-center">
                {t("coachSide.relinkAccountsPage.confirmationBlock.subTitle")}
            </p>
            <p className="mb-5 text-center">{t("coachSide.relinkAccountsPage.confirmationBlock.description")}</p>
            <Button
                isBusy={shouldRefreshButtonBeLoading}
                isDisabled={shouldRefreshButtonBeLoading}
                onClick={handleRefreshPage}
            >
                {t("coachSide.relinkAccountsPage.confirmationBlock.button.title")}
            </Button>
        </div>
    )
}

export { CoachRelinkAccountsConfirmationBox, type TCoachRelinkAccountsConfirmationBoxProps }
