import { useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import Heading from "$/components/Heading/Heading"

import { OldBeliefGoal } from "@/entities/old-belief-goal"
import { BehavioralGoals } from "@/widgets/behavioral-goal/ui/BehavioralGoal"
import CoachingGoal from "@/widgets/coaching-goal/ui/CoachingGoal"

import { GoalsWrapper } from "./ui/GoalsWrapper"

import "./ui/styles.scss"

export const CoachingPlanPageCoach = () => {
    const { t } = useTranslation()
    const [isToggledOldBelief, setIsToggledOldBelief] = useState(false)

    return (
        <div className="goals-layout">
            <GoalsWrapper>
                <div className={classNames("goals-grid", { toggled: isToggledOldBelief })}>
                    <section className={classNames("goals-main", { expanded: isToggledOldBelief })}>
                        <Heading textAlign="center" fontSize={36}>
                            {t("Coaching Plan")}
                        </Heading>
                        <CoachingGoal />
                        <div className="mb-30" />
                        <BehavioralGoals />
                    </section>
                    <aside className={classNames("goal-aside", { toggled: isToggledOldBelief })}>
                        <OldBeliefGoal
                            toggle={() => setIsToggledOldBelief(!isToggledOldBelief)}
                            isToggled={isToggledOldBelief}
                            isCoachSide
                        />
                    </aside>
                </div>
            </GoalsWrapper>
        </div>
    )
}
