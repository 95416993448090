import {
    type CSSProperties,
    type Dispatch,
    type FC,
    type MutableRefObject,
    type ReactElement,
    type ReactNode,
    type SetStateAction,
    useEffect,
    useRef,
    useState
} from "react"

import classNames from "classnames"
import { Transition, type TransitionStatus } from "react-transition-group"

import type { TEmptyCallback } from "@/shared/types/functions"

const defaultStyle: CSSProperties = {
    transition: "height 300ms ease-in-out",
    overflowY: "scroll",
    willChange: "height"
}

type TProps = {
    children: ReactNode
    className?: string
    timeoutInMs?: number
}

const Container: FC<TProps> = ({ children, className = String(), timeoutInMs = 300 }: TProps): ReactElement => {
    const [contentHeight, setContentHeight]: [number, Dispatch<SetStateAction<number>>] = useState<number>(0)

    const contentRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const updateContentHeight: TEmptyCallback = (): void => setContentHeight(contentRef.current?.offsetHeight ?? 0)

    useEffect((): TEmptyCallback => {
        const resizeObserver: ResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]): void =>
            entries.forEach((): void => updateContentHeight())
        )

        if (contentRef?.current) {
            resizeObserver.observe(contentRef.current)
        }

        return (): void => {
            if (contentRef?.current) {
                resizeObserver.unobserve(contentRef.current)
            }
        }
    }, [contentRef.current])

    const transitionStyles: {
        entering: CSSProperties
        entered: CSSProperties
        exiting: CSSProperties
        exited: CSSProperties
    } = {
        entering: { height: `${contentHeight}px` },
        entered: { height: `${contentHeight}px` },
        exiting: { height: 0 },
        exited: { height: 0 }
    }

    return (
        <Transition in appear timeout={timeoutInMs} onEnter={updateContentHeight}>
            {(state: TransitionStatus): ReactElement => (
                <div
                    className="no-scrollbar"
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}
                >
                    <div ref={contentRef} className={classNames({ [className]: className })}>
                        {children}
                    </div>
                </div>
            )}
        </Transition>
    )
}

Container.displayName = "DynamicHeightContainer"

export { Container as DynamicHeightContainer }
