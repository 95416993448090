import { type CSSProperties, type FC, type ReactElement, useId } from "react"

import classNames from "classnames"

import { VerticalProgressBarStarIcon } from "@/svgs/VerticalProgressBarStar"

import { VerticalProgressBarTooltip as Tooltip } from "./vertical-progress-bar-tooltip.component"
import { verticalProgressBarConfig as config } from "./vertical-progress-bar.config"
import {
    EVerticalProgressBarColorStatus as EColorStatus,
    EVerticalProgressBarFillLevel as EFillLevel,
    EVerticalProgressBarTooltipPlacement as ETooltipPlacement,
    EVerticalProgressBarVariant as EVariant,
    type TVerticalProgressBarPieceRenderConfig as TPieceRenderConfig
} from "./vertical-progress-bar.types"
import { verticalProgressBarUtils as utils } from "./vertical-progress-bar.utils"

const piecesClasses: {
    firstPiece(config: TPieceRenderConfig): string
    secondPiece(config: TPieceRenderConfig): string
    thirdPiece(config: TPieceRenderConfig): string
    thirdPieceBottom(config: TPieceRenderConfig): string
} = utils.generatePiecesClasses()

type TProps = {
    fillLevel: EFillLevel
    colorStatus?: EColorStatus
    variant?: EVariant
    withBottomIndicator?: boolean
    isAccented?: boolean
    isAccessible?: boolean
    wrapperClassName?: string
    renderFirstPiece?(): CSSProperties
    renderSecondPiece?(): CSSProperties
    renderThirdPiece?(): CSSProperties
    withoutStar?: boolean
    tooltipConfig?: {
        isEnabled?: boolean
        placement?: ETooltipPlacement
    }
}

const Component: FC<TProps> = ({
    fillLevel: level = EFillLevel.NotFilled,
    colorStatus: status = EColorStatus.Expired,
    variant = EVariant.FullBars,
    withBottomIndicator = false,
    isAccessible = false,
    wrapperClassName = String(),
    renderFirstPiece = undefined,
    renderSecondPiece = undefined,
    renderThirdPiece = undefined,
    withoutStar = false,
    tooltipConfig: { isEnabled: withTooltip = true, placement: tooltipPlacement = ETooltipPlacement.Center } = {
        isEnabled: false
    } as {
        isEnabled?: boolean
        placement?: ETooltipPlacement
    }
}: TProps): ReactElement => {
    const barId: string = useId()

    const color: string = config.barFillColorMap[status]

    function renderTooltip(): ReactElement {
        return withTooltip && isAccessible && <Tooltip barId={barId} barLevel={level} placement={tooltipPlacement} />
    }

    return (
        <div
            {...(withTooltip &&
                isAccessible && {
                    "data-for": barId,
                    "data-tip": barId
                })}
            key={`vpb-${barId}`}
            className={classNames(
                [
                    "flex",
                    "flex-col",
                    "min-w-[35px]",
                    "w-full",
                    "items-center",
                    "h-auto",
                    "relative",
                    "group-[.moduleExcerptsContentAnalytics]:min-w-[50px]",
                    "group-[.moduleExcerptsContentAnalytics]:md:min-w-[43px]"
                ],
                withBottomIndicator && "pb-[5px]",
                {
                    [wrapperClassName]: wrapperClassName
                }
            )}
        >
            <div
                {...(renderFirstPiece && { style: { ...renderFirstPiece?.() } })}
                data-index={1}
                className={piecesClasses.firstPiece({
                    withoutStar,
                    withBoldBorder: variant === EVariant.FullBars,
                    isFilled: [EFillLevel.ThreePiecesFilled].includes(level),
                    isHidden: variant === EVariant.CroppedBars ? level === EFillLevel.NotFilled : false,
                    isAccessible,
                    color
                })}
            >
                {!withoutStar && (
                    <VerticalProgressBarStarIcon
                        className="pointer-events-none"
                        fillColor={utils.getStarFillColor(level === EFillLevel.ThreePiecesFilled)}
                    />
                )}
            </div>

            <div
                {...(renderSecondPiece && { style: { ...renderSecondPiece?.() } })}
                data-index={2}
                className={piecesClasses.secondPiece({
                    withBoldBorder: variant === EVariant.FullBars,
                    isFilled: [EFillLevel.TwoPiecesFilled, EFillLevel.ThreePiecesFilled].includes(level),
                    isHidden: variant === EVariant.CroppedBars ? level === EFillLevel.NotFilled : false,
                    isAccessible,
                    color
                })}
            />

            <div
                {...(renderThirdPiece && { style: { ...renderThirdPiece?.() } })}
                data-index={3}
                className={piecesClasses.thirdPiece({
                    isHidden: variant === EVariant.CroppedBars ? level === EFillLevel.NotFilled : false,
                    isFilled: level !== EFillLevel.NotFilled,
                    isBarFullHeight: variant === EVariant.CroppedBars,
                    isAccessible,
                    color
                })}
            >
                <div
                    className={piecesClasses.thirdPieceBottom({
                        isFilled: level === EFillLevel.NotFilled,
                        isHidden: level !== EFillLevel.NotFilled,
                        isBottomRounded: true,
                        isBarFullHeight:
                            variant === EVariant.CroppedBars &&
                            level === EFillLevel.NotFilled &&
                            color &&
                            status === EColorStatus.NotStarted,
                        color
                    })}
                />
            </div>

            {renderTooltip()}

            {withBottomIndicator && (
                <i
                    className={classNames("w-[5px] min-h-[5px] rounded-full relative top-[10px]", {
                        [color]: color // indicators tbd
                    })}
                />
            )}
        </div>
    )
}

Component.displayName = "VerticalProgressBar"

export { Component as VerticalProgressBar, type TProps as TProgressBarProps }
