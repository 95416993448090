import {
    type ForwardRefExoticComponent,
    type ForwardedRef,
    Fragment,
    type HTMLAttributes,
    type ReactElement,
    type ReactNode,
    forwardRef,
    isValidElement
} from "react"

import classNames from "classnames"

import { ESpinnerVariant, Typography } from "@/3514/components"
import { isEqual } from "@/3514/utils"

import { ESpinnerSize, Spinner } from "../spinner"

import { buttonConfig } from "./button.config"
import { EButtonSize, EButtonVariant } from "./button.types"

type TProps = Partial<HTMLAttributes<HTMLButtonElement>> &
    Omit<HTMLAttributes<HTMLButtonElement>, "disabled" | "children"> & {
        variant?: EButtonVariant
        size?: EButtonSize
        isBusy?: boolean
        isDisabled?: boolean
        isCentered?: boolean
        type?: "button" | "submit" | "reset"
        children?: ReactNode | ReactNode[] | string
    }

const Component: ForwardRefExoticComponent<TProps> = forwardRef<HTMLButtonElement, TProps>(
    (
        {
            className = String(),
            variant = EButtonVariant.Solid,
            size = EButtonSize.Medium,
            isDisabled = false,
            isBusy = false,
            onClick,
            children,
            isCentered = false,
            type = "submit",
            ...rest
        }: TProps,
        ref: ForwardedRef<HTMLButtonElement>
    ): ReactElement => {
        function renderSpinner(): ReactNode {
            return (
                <div className={classNames("w-100 h-100", size === EButtonSize.Medium && "py-[2px]")}>
                    <Spinner
                        variant={variant === EButtonVariant.Solid ? ESpinnerVariant.White : ESpinnerVariant.Accent}
                        size={ESpinnerSize.Small}
                    />
                </div>
            )
        }

        function renderChildren(): ReactNode {
            if (isEqual(typeof children, "string"))
                return <Typography text={children} {...buttonConfig.getTypographyProps(size, variant, isDisabled)} />
            if (Array.isArray(children))
                return children.map(
                    (c: ReactNode): ReactNode => (
                        <Fragment>
                            {isValidElement(c) && c}
                            {isEqual(typeof c, "string") && (
                                <Typography text={c} {...buttonConfig.getTypographyProps(size, variant, isDisabled)} />
                            )}
                        </Fragment>
                    )
                )
            return children
        }

        return (
            <button
                className={classNames(className, "flex", {
                    [buttonConfig.baseClasses]: true,
                    [buttonConfig.sizeMap[size]]: size,
                    [buttonConfig.colorMap[variant](isDisabled)]: variant,
                    "mx-auto": isCentered
                })}
                onClick={onClick}
                disabled={isBusy || isDisabled}
                aria-disabled={isBusy || isDisabled}
                ref={ref}
                type={type}
                {...rest}
            >
                {isBusy ? renderSpinner() : renderChildren()}
            </button>
        )
    }
)

Component.displayName = "Button"

export { Component as Button, type TProps as TButtonProps }
