import { useCallback, useMemo, useRef, useState } from "react"

import CronofyWidget from "$/components/CronofyWidgets/CronofyWidget"
import Heading from "$/components/Heading/Heading"
import { CRONOFY_WIDGET } from "$/constants"
import { http } from "$/http"
import { getErrorMessages } from "$/utils/get-error-messages"

import { Button } from "@/3514/components"
import Urls from "@/api/urls"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { useAvailability } from "@/entities/coach-calendar/api/use-availavility-rules-attrs"
import { useOrderNavigationCalendar } from "@/entities/coach-calendar/hooks/use-order-navigation-calendar"
import { SimpleLayout } from "@/layouts"
import DateWidget from "@/shared/DateWidget"
import { FullSpinner } from "@/shared/spinner"

import { CoachCalendarStepUrls } from "./constants"
import { StepsNavigator } from "./ui/StepNavigator"

type Props = {
    order?: number
}

const AvailabilityStep = ({ order }: Props) => {
    const { navigateByOrder } = useOrderNavigationCalendar(order)
    const viewerRef = useRef<HTMLDivElement>()
    const { data, isLoading } = useAvailability()
    const [rules, setRules] = useState(null)
    const [error, setError] = useState(null)

    const onClick = async () => {
        try {
            const btn = viewerRef?.current?.querySelector(".av__submit") as HTMLButtonElement
            btn?.click()
            if (rules) {
                await http.post(Urls.avRules(), rules.availability_rule)
            }
            navigateByOrder()
        } catch (error) {
            setError(getErrorMessages(error))
        }
    }

    const onAvailabilitySelect = useCallback(async data => {
        if (data) {
            try {
                setRules(data)
            } catch (error) {
                setError(getErrorMessages(error))
            }
        }
    }, [])

    const memoAvailabilityRules = useMemo(
        () => (
            <CronofyWidget
                ref={viewerRef}
                widget={CRONOFY_WIDGET.AvailabilityRules}
                id="availability-rules-calendar"
                options={{
                    ...data,
                    styles: {
                        prefix: "av"
                    },
                    callback: onAvailabilitySelect
                }}
            />
        ),
        [data]
    )

    return (
        <SimpleLayout
            header={<DateWidget className="coach-date-title" />}
            headerVariant="gray"
            showBackBtn
            showProfilePhoto
            className="coach-edit-availability-layout"
        >
            <div className="coach-calendar-wrapper mx-auto pb-5">
                <div className="d-flex justify-content-center mt-5">
                    <StepsNavigator activeStep={CoachCalendarStepUrls.AVAILABILITY_STEP} />
                </div>
                <Heading textAlign="center" tag="h6" fontSize={16} className="mb-40 mt-5 text-accentOld">
                    Step {order}
                </Heading>
                <Heading tag="h1" textAlign="center" className="mb-2" fontSize={36}>
                    Edit Your Working Hours
                </Heading>

                <p className="color-gray fs-14 text-center mb-0">You may now manually tweak your available hours.</p>
                <p className="color-gray fs-14 text-center mb-40">
                    Drag your mouse up & down across the grid to adjust your availability.
                </p>

                {isLoading ? <FullSpinner /> : memoAvailabilityRules}
                <WarningMessage message={error} />
                <div className="mx-auto w-min mt-50">
                    <Button className="!bg-accentOld" onClick={onClick}>
                        Next
                    </Button>
                </div>
            </div>
        </SimpleLayout>
    )
}

export default AvailabilityStep
