import { isEmpty } from "$/utils/gates"

import type { TUserModel } from "@/adapters"
import { StepUrls, dashboardURL } from "@/components/onboarding/constants"
import { CoachUrls, ParticipantUrls, Status, userRoles } from "@/constants"
import type { EnrolledModule } from "@/models/user"
import { EStorageServiceType, SessionStorageServiceKeys, StorageService } from "@/services"

const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

export const getIndexPage = (user: TUserModel, showDashboard) => {
    if (userRoles.coach.includes(user.role)) {
        if (
            sessionStorageService.getItem(SessionStorageServiceKeys.FakeParticipantsFeatureEnabled()) ||
            user?.hasSubUsers
        ) {
            return CoachUrls.WELCOME
        }
        return CoachUrls.SESSIONS_LIST
    }

    if (!user.onboardingFinished) {
        return StepUrls.ONBOARDING_REDIRECT_PAGE
    }

    if (user?.hasNotCompletedSurveys) {
        return ParticipantUrls.SURVEYS
    }

    if (showDashboard) {
        if (user?.modulesAvailable) {
            if (user?.module?.rank) {
                return `${dashboardURL}/${user.module.rank}`
            }

            if (!user?.module) {
                // @ts-expect-error findLast seems to be not available with installed version of TS
                const lastEnrolledModule: EnrolledModule = user?.enrolledModules?.findLast(
                    ({ status }: EnrolledModule): boolean => status !== Status.Unassigned
                )

                if (!isEmpty(lastEnrolledModule)) {
                    return `${dashboardURL}/${lastEnrolledModule.rank}`
                }
            }

            return dashboardURL
        }
    }

    return ParticipantUrls.SESSION_LIST
}
