import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

import Heading from "$/components/Heading/Heading"

import { Button } from "@/3514/components"
import { type IAuthContext, useAuthContext } from "@/context"
import TextArea from "@/shared/input/TextArea"
import TextInput from "@/shared/input/TextInput"
import Modal from "@/shared/modal"
import { isParticipant } from "@/utils/gates/user"

import { OldBelief } from "../model/types/old-belief"

import "./styles.scss"

type Props = {
    isOpen: boolean
    oldBeliefIndex: number
    oldBeliefs: any
    onClose: () => void
    onSubmit: (values?: Pick<OldBelief, "title" | "description">, index?: number) => void
}

export const OldBeliefGoalModal = ({ isOpen, oldBeliefIndex, oldBeliefs, onClose, onSubmit }: Props) => {
    const { t } = useTranslation()
    const values = oldBeliefs[oldBeliefIndex]

    const { user }: IAuthContext = useAuthContext()

    const isCoachSide: boolean = !isParticipant(user)

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        shouldUnregister: true,
        defaultValues: values,
        resolver: yupResolver(
            yup.object({
                title: yup.string().required(t("Field is required"))
            })
        )
    })

    const onSubmitOldBelief = values => {
        onSubmit(values)
    }

    return (
        <Modal
            onClose={onClose}
            show={isOpen}
            headerContent={
                <Heading className="text-center mb-0 p-3" fontSize={24}>
                    {t("Add / Edit Old Belief")}
                </Heading>
            }
            modalBodyClass="old-belief-modal"
        >
            <div className="old-belief-modal__body">
                <p className="mb-20">
                    {t(
                        "What’s one old belief that has been holding you back? What is the significance of this old belief in your past & present stages of life?"
                    )}
                </p>
                <form onSubmit={handleSubmit(onSubmitOldBelief)}>
                    <TextInput
                        groupClassName="mb-10 w-100 gray-placeholder"
                        variant="rect"
                        maxLength={60}
                        customPlaceholder={
                            <>
                                <span className="color-gray font-extrabold">{t("Title")}</span>{" "}
                                <span className="color-gray">
                                    ({t("required, {{number}} character max", { number: 60 })})
                                </span>
                            </>
                        }
                        register={register}
                        name="title"
                        errors={errors}
                    />

                    <TextArea
                        variant="rect"
                        className="mb-40 w-100 gray-placeholder"
                        rows={3}
                        placeholder={t("Description of Significance (optional)")}
                        register={register}
                        name="description"
                        errors={errors}
                    />

                    <div className="w-min mx-auto mt-40">
                        <Button className={classNames(isCoachSide && "!bg-accentOld")}>{t("Done")}</Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}
