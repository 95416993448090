import ContentLoader from "react-content-loader"
import { Trans } from "react-i18next"
import sanitizeHtml from "sanitize-html"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"

import useModulesByCategory from "../api/use-modules-category"

import Accordion from "./Accordion"

const ModulesCategory = () => {
    const { data: modules, isLoading } = useModulesByCategory()

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    if (isLoading) {
        return (
            <>
                {Array(3)
                    .fill("")
                    .map((_, index) => (
                        <div className="w-100 mb-20" style={{ height: 350 }} key={index}>
                            <ContentLoader
                                speed={3}
                                height={350}
                                width="100%"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb"
                            >
                                <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
                                <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
                            </ContentLoader>
                        </div>
                    ))}
            </>
        )
    }

    return (
        <>
            {modules?.map(module => (
                <div key={module?.category?.id} className="module-card w-100">
                    <p>
                        <Trans i18nKey="<0>Modules for </0><1>{categoryName}</1>">
                            <span className="fs-16 font-weight-bold">Modules for </span>
                            <span className="fs-16 font-weight-bold color-orange">
                                {/* @ts-expect-error wtf is below */}
                                {{ categoryName: getValueFromTranslatedObject(module.category.name) }}
                            </span>
                        </Trans>
                    </p>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(getValueFromTranslatedObject(module?.category?.description))
                        }}
                    />
                    {module?.modules.map(item => (
                        <div key={item.id} className="d-flex mx-auto">
                            <Accordion
                                version={getValueFromTranslatedObject(item?.titleVersion)}
                                content={getValueFromTranslatedObject(item?.longDescription)}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </>
    )
}

export default ModulesCategory
