import { FC } from "react"

import classNames from "classnames"

import LiveNowSvg from "@/svgs/LiveNowSvg"

import "./styles.scss"

interface IProps {
    joinLink?: string
    isCoachSide?: boolean
}

const LiveNowLine: FC<IProps> = ({ joinLink, isCoachSide = false }) => {
    return (
        <div className="live-now" data-testid="live-now-line">
            <div className={classNames("live-now-line left", isCoachSide && "!bg-accentOld")} />
            <div className="live-now-widget">
                <LiveNowSvg isCoachSide={isCoachSide} />
                <a
                    href={joinLink}
                    target="_blank"
                    className={classNames("live-now-link", { "disable-link": !joinLink })}
                    rel="noreferrer"
                >
                    <p className={classNames("live-now-text", isCoachSide && "!text-accentOld")}>Live Now</p>
                </a>
            </div>
            <div className={classNames("live-now-line rigth", isCoachSide && "!bg-accentOld")} />
        </div>
    )
}

export default LiveNowLine
