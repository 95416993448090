import classNames from "classnames"
import { Link } from "react-router-dom"

import Heading from "$/components/Heading/Heading"
import { isEmptyString } from "$/utils/gates"
import { getErrorMessages } from "$/utils/get-error-messages"

import { Button } from "@/3514/components"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { useZoom } from "@/entities/coach-calendar/api/use-zoom"
import { useOrderNavigationCalendar } from "@/entities/coach-calendar/hooks/use-order-navigation-calendar"
import { SimpleLayout } from "@/layouts"
import DateWidget from "@/shared/DateWidget"
import Card from "@/shared/card/Card"
import ActiveCheckMarkSvg from "@/svgs/ActiveCheckMarkSvg"

import { CoachCalendarStepUrls } from "./constants"
import { StepsNavigator } from "./ui/StepNavigator"

type Props = {
    order?: number
}

const LinkAccountStep = ({ order }: Props) => {
    const { nextOrderStep } = useOrderNavigationCalendar(order)
    const { data, isError, error } = useZoom()

    const hasUrl: boolean = !isEmptyString(data?.url)

    return (
        <SimpleLayout
            header={<DateWidget className="coach-date-title" />}
            headerVariant="gray"
            showBackBtn
            showProfilePhoto
            withUserDropdownWithoutMargin
            className="coach-link-account-layout"
        >
            <div className="coach-calendar-wrapper mx-auto pb-5">
                <div className="d-flex justify-content-center mt-5">
                    <StepsNavigator activeStep={CoachCalendarStepUrls.LINK_ACCOUNT_STEP} />
                </div>
                <Heading textAlign="center" tag="h6" fontSize={16} className="mb-40 mt-5 text-accentOld">
                    Step {order}
                </Heading>
                <Heading tag="h1" textAlign="center" className="mb-2" fontSize={36}>
                    Link Your Zoom Account
                </Heading>

                <p className="color-gray fs-14 text-center mb-40">
                    Connect your Zoom account in order to access your video coaching sessions. Clicking the button below
                    will take you to Zoom to verify your credentials.
                </p>

                <Card>
                    <div className={classNames("d-flex align-items-center", { "justify-content-center": hasUrl })}>
                        {hasUrl ? (
                            <p className="text-center m-0 color-gray font-italic">No accounts linked yet.</p>
                        ) : (
                            <>
                                <div className="flex-1">
                                    <span>{data?.profile_name}</span>
                                </div>
                                <div className="flex items-center">
                                    <span className="text-accentOld font-extrabold mr-2">Success</span>
                                    <ActiveCheckMarkSvg />
                                </div>
                            </>
                        )}
                    </div>
                </Card>

                {isError && <WarningMessage message={getErrorMessages(error?.response?.data)?.join("")} />}

                <div className="mx-auto w-fit mt-50">
                    {hasUrl ? (
                        <a href={data.url} className="color-inherit">
                            <Button className="!bg-accentOld">Link My Account</Button>
                        </a>
                    ) : (
                        <Link to={nextOrderStep}>
                            <Button className="!bg-accentOld">Next</Button>
                        </Link>
                    )}
                </div>
            </div>
        </SimpleLayout>
    )
}

export default LinkAccountStep
