import {
    type FC,
    type KeyboardEvent,
    type MutableRefObject,
    type ReactElement,
    type ReactNode,
    useCallback,
    useEffect,
    useRef
} from "react"

import classNames from "classnames"

import { useKeyPress } from "$/hooks/use-key-press"
import { useOnClickOutside } from "$/hooks/use-outside-click"

import {
    Button,
    EButtonSize,
    EButtonVariant,
    ETypographyAlignment,
    ETypographyFontWeight,
    ETypographySize,
    ETypographyTag,
    Typography
} from "@/3514/components"
import { type TUseModal, useModal } from "@/3514/hooks"
import { EModalId } from "@/3514/store/slices"
import { getTransitionTwClasses } from "@/3514/utils"
import { MAIN_PORTAL_ID } from "@/constants"
import { type IUseTranslation, useTranslation } from "@/hooks"
import Portal from "@/shared/portal/Portal"
import { type TEmptyCallback } from "@/shared/types/functions"
import { CrossIconSvg } from "@/svgs/CrossIconSvg"

type TProps = {
    isShown: boolean
    modalId: EModalId
    onHide?(): void
    titleText?: string
    modalBodyClassName?: string
    modalHeaderClassName?: string
    modalWrapperClassName?: string
    withOutsideClick?: boolean
    withDisabledBodyScroll?: boolean
    shouldCloseOnEscapeClick?: boolean
    withPortal?: boolean
    portalId?: string
    withCloseIcon?: boolean
    children?: ReactNode
    withStickyHeading?: boolean
}

const classes = {
    modalWrapper: [
        "h-full",
        "w-screen",
        "bg-black/25",
        "backdrop-blur-sm",
        "fixed",
        "top-0",
        "left-0",
        "flex",
        "items-center",
        "justify-center",
        "transition-opacity",
        "duration-500",
        "transform",
        "scale-0"
    ],
    modalWrapperShown: "opacity-100 scale-100 z-[100]",
    modalBody: classNames("relative bg-white transform scale-50 h-fit", getTransitionTwClasses("all", 400)),
    modalBodyShown: "!scale-100 z-[120]",
    modalHeader: (isSticky: boolean): string =>
        classNames(isSticky ? "sticky h-fit z-[119] top-[0px] bg-white" : "relative"),
    closeBtn: (isHeaderSticky: boolean): string =>
        classNames(
            [
                "top-[17px]",
                "right-[17px]",
                "focus:!outline-[1px]",
                "focus:!outline",
                "focus:!outline-gray-23",
                "hover:!outline-[1px]",
                "hover:!outline",
                "hover:!outline-gray-23"
            ],
            isHeaderSticky ? "absolute md:fixed" : "fixed"
        )
}

const Component: FC<TProps> = ({
    isShown,
    modalId,
    onHide,
    titleText = String(),
    modalBodyClassName = String(),
    modalHeaderClassName = String(),
    modalWrapperClassName = String(),
    withOutsideClick = true,
    withDisabledBodyScroll = false,
    shouldCloseOnEscapeClick = true,
    withPortal = true,
    withCloseIcon = true,
    portalId = MAIN_PORTAL_ID,
    children,
    withStickyHeading = false
}: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const { hideModal }: TUseModal = useModal()

    const handleHide: TEmptyCallback = useCallback(
        (): void => (hideModal(modalId), onHide?.()),
        [hideModal, modalId, onHide]
    )

    const modalBodyRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>()

    useEffect((): TEmptyCallback => {
        if (isShown && withDisabledBodyScroll) {
            document.body.classList.add("!overflow-hidden")
        } else {
            document.body.classList.remove("!overflow-hidden")
        }

        return (): void => document.body.classList.remove("!overflow-hidden")
    }, [isShown, withDisabledBodyScroll])

    useKeyPress((): void => isShown && shouldCloseOnEscapeClick && handleHide(), ["Escape"])

    useOnClickOutside(modalBodyRef, (): void => (withOutsideClick && isShown ? handleHide() : void 0))

    useEffect((): void => isShown && modalBodyRef.current?.focus(), [isShown])

    return (
        isShown && (
            <Portal withPortal={withPortal} portalId={portalId}>
                <div
                    className={classNames(classes.modalWrapper, isShown && classes.modalWrapperShown, {
                        [modalWrapperClassName]: modalWrapperClassName
                    })}
                >
                    <div
                        className={classNames(classes.modalBody, isShown && classes.modalBodyShown, {
                            [modalBodyClassName]: modalBodyClassName
                        })}
                        role="dialog"
                        ref={modalBodyRef}
                        tabIndex={0}
                    >
                        <div
                            className={classNames(classes.modalHeader(withStickyHeading), {
                                [modalHeaderClassName]: modalHeaderClassName
                            })}
                        >
                            {titleText && (
                                <Typography
                                    text={titleText}
                                    size={ETypographySize.Heading2}
                                    tag={ETypographyTag.Heading2}
                                    weight={ETypographyFontWeight.Bold}
                                    alignment={ETypographyAlignment.Center}
                                    className="mb-0"
                                />
                            )}
                            {withCloseIcon && (
                                <Button
                                    variant={EButtonVariant.EmptyTransparent}
                                    size={EButtonSize.Container}
                                    aria-label={t("Close button")}
                                    className={classNames(classes.closeBtn(withStickyHeading))}
                                    onClick={handleHide}
                                    onKeyDown={(e: KeyboardEvent): void => e.key === "Enter" && handleHide()}
                                >
                                    <CrossIconSvg />
                                </Button>
                            )}
                        </div>
                        {children}
                    </div>
                </div>
            </Portal>
        )
    )
}

Component.displayName = "Modal"

export { Component as Modal, type TProps as TModalProps }
