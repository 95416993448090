import { useMemo, useRef, useState } from "react"

import CronofyWidget from "$/components/CronofyWidgets/CronofyWidget"
import Heading from "$/components/Heading/Heading"
import { CRONOFY_WIDGET } from "$/constants"
import { http } from "$/http"
import { getErrorMessages } from "$/utils/get-error-messages"

import { Button } from "@/3514/components"
import Urls from "@/api/urls"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { useAvailability } from "@/entities/coach-calendar/api/use-availavility-rules-attrs"
import { useAvailabilityConfirmRules } from "@/entities/coach-calendar/api/use-confirm"
import { CoachLayout } from "@/layouts"
import DateWidget from "@/shared/DateWidget"
import { FullSpinner } from "@/shared/spinner"

import LeftSidebar from "./ui/LeftSidebar"

import "./styles.scss"

const CoachAvailability = () => {
    const { data: availabilityRules, isLoading: isLoadingAvailabilityRules } = useAvailability()
    const { data: availabilityConfirm, isLoading: isLoadingAvailabilityConfirm } = useAvailabilityConfirmRules()
    const viewerRef = useRef<HTMLDivElement>()
    const [rules, setRules] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [isBusy, setIsBusy] = useState(false)
    const [error, setError] = useState(null)

    const onAvailabilitySelect = async data => {
        if (data) {
            try {
                setRules(data)
            } catch (e) {
                setError(getErrorMessages(e))
            }
        }
    }

    const onSaveAvailability = async () => {
        if (rules) {
            try {
                setIsBusy(true)
                const btn = viewerRef?.current?.querySelector(".av__submit") as HTMLButtonElement
                btn?.click()
                await http.post(Urls.avRules(), rules.availability_rule)
                setIsEdit(false)
            } catch (e) {
                setError(getErrorMessages(e))
            } finally {
                setIsBusy(false)
            }
        }
    }

    const AvailabilityEdit = useMemo(() => {
        return isLoadingAvailabilityRules ? (
            <FullSpinner />
        ) : (
            <CronofyWidget
                ref={viewerRef}
                widget={CRONOFY_WIDGET.AvailabilityRules}
                key={CRONOFY_WIDGET.AvailabilityRules}
                id="availability-rules-calendar"
                options={{
                    ...availabilityRules,
                    styles: {
                        prefix: "av"
                    },
                    callback: onAvailabilitySelect
                }}
            />
        )
    }, [isLoadingAvailabilityRules, availabilityRules, isEdit])

    const AvailabilityHours = useMemo(() => {
        const config = {
            ...(availabilityConfirm ?? {}),
            callback: () => {}
        }
        return isLoadingAvailabilityConfirm ? (
            <FullSpinner />
        ) : (
            <CronofyWidget
                widget={CRONOFY_WIDGET.AvailabilityViewer}
                id="av-viewer"
                options={config}
                key={CRONOFY_WIDGET.AvailabilityViewer}
            />
        )
    }, [availabilityConfirm, isLoadingAvailabilityConfirm, isEdit])

    return (
        <CoachLayout
            leftSidebar={<LeftSidebar />}
            headerTitle={<DateWidget className="coach-date-title" />}
            fullWidth
            withContainer={false}
            align="left"
        >
            <div className="coach-calendars-wrapper coach-availability">
                <Heading tag="h1" className="mb-1 mt-50" fontSize={36}>
                    {isEdit ? "Edit Working Hours" : "My Working Hours"}
                </Heading>
                <p className="color-gray fs-14 mb-4">
                    {isEdit
                        ? "You may now manually tweak your available hours. Drag your mouse up & down across the grid to adjust your availability."
                        : "This is the availability that will be visible to your 1:1 participants, as well as our Wiser Care Team. If your availability has changed, please edit it below."}
                </p>

                {isEdit ? AvailabilityEdit : AvailabilityHours}

                <div className="mt-50">
                    <WarningMessage message={error} />
                    {isEdit ? (
                        <Button className="!bg-accentOld" onClick={onSaveAvailability} isBusy={isBusy}>
                            Save
                        </Button>
                    ) : (
                        <Button className="!bg-accentOld" onClick={() => setIsEdit(true)}>
                            Edit Working Hours
                        </Button>
                    )}
                </div>
            </div>
        </CoachLayout>
    )
}

export default CoachAvailability
