import type { FC, ReactElement } from "react"

import classnames from "classnames"

import { THEME } from "@/3514/config"

type TProps = { width?: number; height?: number; className?: string }

const Icon: FC<TProps> = ({ width = 18, height = 18, className = String() }: TProps): ReactElement => (
    <svg
        className={classnames({ [className]: className })}
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.0625 9.1125L7.64062 11.8125L13.5 6.1875"
            stroke={THEME.COLORS.gray[23]}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

Icon.displayName = "CheckMarkIcon"

export { Icon as CheckMarkIcon }
