import {
    type BaseSyntheticEvent,
    type Dispatch,
    Fragment,
    type KeyboardEvent,
    type MouseEvent,
    type MutableRefObject,
    type NamedExoticComponent,
    type ReactElement,
    type SetStateAction,
    memo,
    useRef,
    useState
} from "react"

import classNames from "classnames"

import { useOnClickOutside } from "$/hooks/use-outside-click"

import { isEmpty, isEqual } from "@/3514/utils"
import { type IUseTranslation, useTranslation } from "@/hooks"

import { ChevronIcon } from "../../assets"
import { ESpecialChar, SpecialChars } from "../special-char"
import { ESpinnerSize, Spinner } from "../spinner"
import { ETypographyColor, ETypographyFontWeight, ETypographySize, ETypographyTag, Typography } from "../typography"

import { selectConfig } from "./select.config"
import { ESelectSize, ESelectVariant, type ISelectOption } from "./select.types"

type TProps = {
    variant?: ESelectVariant
    size?: ESelectSize
    isDisabled?: boolean
    isLoading?: boolean
    label?: string
    defaultValue?: string
    options: ISelectOption[]
    onChange(value: string): void
    placeholder?: string
    wrapperClassName?: string
}

function renderLabel(label: string): ReactElement {
    return (
        !isEmpty(label) && (
            <Typography
                text={
                    <Fragment>
                        {label}
                        {SpecialChars[ESpecialChar.Colon]}
                    </Fragment>
                }
            />
        )
    )
}

const Component: NamedExoticComponent<TProps> = memo(
    ({
        variant = ESelectVariant.Default,
        size = ESelectSize.Medium,
        isDisabled = false,
        isLoading = false,
        label = String(),
        defaultValue = String(),
        options,
        onChange,
        placeholder = "– –",
        wrapperClassName = String()
    }: TProps): ReactElement => {
        const { t }: IUseTranslation = useTranslation()

        const [isSelectOpen, setIsSelectOpen]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

        const [currentOptionIndex, setCurrentOptionIndex]: [number, Dispatch<SetStateAction<number>>] =
            useState<number>(0)

        const [selectedOption, setSelectedOption]: [ISelectOption, Dispatch<SetStateAction<ISelectOption>>] =
            useState<ISelectOption>(
                isLoading
                    ? { label: String(""), value: t("common.loading"), id: String(), isDisabled }
                    : {
                          label: String(defaultValue || placeholder),
                          value: String(defaultValue || placeholder),
                          id: String(defaultValue || ""),
                          isDisabled
                      }
            )

        const wrapperRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>()
        const optionsListRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>()

        const handleClickOutSide: (e: UIEvent) => void = (e: UIEvent): void =>
            // @ts-expect-error wrong tye below
            wrapperRef?.current.contains(e.target) ? void 0 : (e.stopPropagation(), setIsSelectOpen(false))

        useOnClickOutside(optionsListRef, handleClickOutSide)

        function renderOption(option: ISelectOption, withBlackColor: boolean = false): ReactElement {
            return (
                <Typography
                    text={option.label}
                    weight={ETypographyFontWeight.Bold}
                    className={selectConfig.baseClasses.optionText()}
                    color={
                        option.isDisabled
                            ? ETypographyColor.DarkGray
                            : withBlackColor
                              ? ETypographyColor.Black
                              : ETypographyColor.Accent
                    }
                    size={ETypographySize.Small}
                    tag={ETypographyTag.Span}
                />
            )
        }

        const handleOptionAction: (e: BaseSyntheticEvent, index: number) => void = (
            e: KeyboardEvent & MouseEvent,
            index: number
        ): void =>
            (e.key === "Enter" || e.type === "click") &&
            (!options[index].isDisabled
                ? (setSelectedOption(options[index]),
                  setCurrentOptionIndex(index),
                  setIsSelectOpen(false),
                  onChange(options[index].id))
                : void 0)

        const handleSelectAction: (e: BaseSyntheticEvent) => void = (e: KeyboardEvent & MouseEvent): void => {
            if (!isSelectOpen && (e.key === "Enter" || e.type === "click")) {
                if (!isDisabled) setIsSelectOpen((prev: boolean): boolean => !prev)
            } else if (isSelectOpen) {
                if (e.key === "Enter")
                    handleOptionAction(
                        e,
                        !isEmpty((e.target as HTMLElement).dataset.index)
                            ? Number((e.target as HTMLElement).dataset.index)
                            : currentOptionIndex
                    )
                if (e.type === "click") {
                    if (!isEmpty((e.target as HTMLElement).dataset.index)) {
                        handleOptionAction(e, Number((e.target as HTMLElement).dataset.index))
                    } else {
                        setIsSelectOpen(false)
                    }
                }

                if (e.key === "Tab") {
                    e.stopPropagation()
                    e.preventDefault()
                    if (e.shiftKey) {
                        setCurrentOptionIndex((prev: number): number => (prev - 1 === 0 || prev === 0 ? 0 : prev - 1))
                    } else {
                        setCurrentOptionIndex((prev: number): number =>
                            prev + 1 === options?.length ? prev : prev + 1
                        )
                    }
                } else {
                    switch (e.key) {
                        case "Escape":
                            e.stopPropagation()
                            e.preventDefault()
                            setIsSelectOpen(false)
                            break
                        case "ArrowDown":
                            e.stopPropagation()
                            e.preventDefault()
                            setCurrentOptionIndex((prev: number): number =>
                                prev + 1 === options?.length ? prev : prev + 1
                            )
                            break
                        case "ArrowUp":
                            e.stopPropagation()
                            e.preventDefault()
                            setCurrentOptionIndex((prev: number): number =>
                                prev - 1 === 0 || prev === 0 ? 0 : prev - 1
                            )
                            break
                        default:
                            break
                    }
                }
            }
        }

        return (
            <div
                className={classNames(selectConfig.baseClasses.container(), {
                    [wrapperClassName]: wrapperClassName
                })}
            >
                {renderLabel(label)}

                <div
                    className={classNames(selectConfig.baseClasses.wrapper(), {
                        [selectConfig.sizeClassesMap[size].wrapper()]: size
                    })}
                >
                    <div
                        role="button"
                        aria-haspopup="listbox"
                        aria-expanded={isSelectOpen}
                        aria-disabled={isDisabled}
                        aria-label="Open select"
                        tabIndex={0}
                        className={classNames({
                            [selectConfig.variantClassesMap[variant].wrapper(
                                isSelectOpen,
                                isDisabled,
                                isEmpty(defaultValue)
                            )]: variant
                        })}
                        ref={wrapperRef}
                        onClick={handleSelectAction}
                        onKeyDown={handleSelectAction}
                    >
                        {renderOption(selectedOption, selectedOption.value === placeholder)}
                        <ChevronIcon className={selectConfig.baseClasses.icon(isSelectOpen)} isDisabled={isDisabled} />
                    </div>

                    {isSelectOpen && (
                        <div
                            role="listbox"
                            tabIndex={-1}
                            onClick={handleSelectAction}
                            onKeyDown={handleSelectAction}
                            ref={optionsListRef}
                            className={classNames({
                                [selectConfig.variantClassesMap[variant].container()]: variant,
                                [selectConfig.sizeClassesMap[size].container()]: size
                            })}
                        >
                            {isLoading ? (
                                <Spinner size={ESpinnerSize.Small} className={selectConfig.baseClasses.spinner()} />
                            ) : isEmpty(options) ? (
                                <Typography
                                    tag={ETypographyTag.Span}
                                    text="No options available."
                                    className={selectConfig.baseClasses.emptyOptionsText()}
                                />
                            ) : (
                                options.map(
                                    (option: ISelectOption, index: number): ReactElement => (
                                        <div
                                            key={option.id}
                                            id={option.id}
                                            tabIndex={0}
                                            role="option"
                                            data-index={index}
                                            aria-selected={selectedOption.value === option.value}
                                            className={classNames({
                                                [selectConfig.variantClassesMap[variant].option(
                                                    selectedOption.id === option.id,
                                                    currentOptionIndex === index,
                                                    option.isDisabled
                                                )]: variant
                                            })}
                                        >
                                            {renderOption(option)}
                                        </div>
                                    )
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    },
    (nextProps: Readonly<TProps>, prevProps: Readonly<TProps>): boolean => isEqual(nextProps, prevProps)
)

Component.displayName = "Select"

export { Component as Select }
