import { EBadgeRoundVariant, EBadgeVariant } from "./badge.types"

const _baseClasses: string = "transition-colors duration-200"

const badgeColorsMap: { [K in EBadgeVariant]: (interactive: boolean, hoverable: boolean) => string } = {
    [EBadgeVariant.Yellow]: (interactive: boolean, hoverable: boolean): string =>
        [_baseClasses, interactive ? "!bg-yellow-100" : hoverable && "hover:bg-yellow-100", "bg-yellow-200"]
            .filter(Boolean)
            .join(" "),
    [EBadgeVariant.Green]: (interactive: boolean, hoverable: boolean): string =>
        [_baseClasses, interactive ? "!bg-green-200" : hoverable && "!hover:bg-green-200", "bg-green-300"]
            .filter(Boolean)
            .join(" "),
    [EBadgeVariant.LightGreen]: (interactive: boolean, hoverable: boolean): string =>
        [_baseClasses, interactive ? "bg-green-300" : hoverable && "hover:bg-green-300", "bg-green-400"]
            .filter(Boolean)
            .join(" "),
    [EBadgeVariant.DarkGreen]: (
        interactive: boolean,
        hoverable: boolean
    ): string => // duplicate Green, remove
        [_baseClasses, interactive ? "bg-green-200" : hoverable && "hover:bg-green-200"].filter(Boolean).join(" "),
    [EBadgeVariant.Lightblue]: (interactive: boolean, hoverable: boolean): string =>
        [_baseClasses, interactive ? "!bg-lightblue-200" : hoverable && "hover:bg-lightblue-200", "bg-lightblue-300"]
            .filter(Boolean)
            .join(" ")
}

const badgeRoundVariant: { [K in EBadgeRoundVariant]: string } = {
    [EBadgeRoundVariant.Small]: "rounded-[10px]",
    [EBadgeRoundVariant.Medium]: "rounded-[20px]"
}

const config = { badgeColorsMap, badgeRoundVariant }

export { config as badgeConfig }
