import classNames from "classnames"

import { getTransitionTwClasses } from "@/3514/utils"

import { ECheckboxVariant } from "./checkbox.types"

const _baseWrapperClasses: string[] = [
    "h-[30px]",
    "w-[30px]",
    "scale-50",
    "border-[2px]",
    "border-blue-200",
    "rounded-[6px]",
    "flex",
    "items-center",
    "justify-center",
    "cursor-pointer",
    "group",
    getTransitionTwClasses("colors")
]
const _baseIndicatorClasses: string[] = ["h-[6px]", "w-[6px]", "scale-[2]", "rounded-full", "pointer-events-none"]

const colorMap: {
    [V in ECheckboxVariant]: (
        isActionAffected: boolean,
        isChecked: boolean,
        isDisabled: boolean
    ) => { wrapper: string; indicator: string }
} = {
    [ECheckboxVariant.Default]: (
        isActionAffected: boolean,
        isChecked: boolean,
        isDisabled: boolean
    ): { wrapper: string; indicator: string } => ({
        wrapper: classNames(
            _baseWrapperClasses,
            isChecked || isActionAffected ? "bg-blue-400" : "bg-white",
            isDisabled && "pointer-events-none"
        ),
        indicator: classNames(
            _baseIndicatorClasses,
            // WISER-4099 group-focus:bg-blue-400 should not be present, 10x to PM
            isChecked ? "!bg-accent" : "bg-transparent"
        )
    })
}

const config = { colorMap }

export { config as checkboxConfig }
