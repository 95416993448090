import { FC, memo, useCallback, useEffect, useMemo, useRef } from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { Link, matchPath, useLocation } from "react-router-dom"

import useMediaQuery from "$/hooks/use-media-query"
import { isEmpty } from "$/utils/gates"

import { ERoutePath } from "@/3514/constants"
import useCoachingSessions from "@/api/use-coaching-sessions"
import useOnboardingSteps from "@/api/use-onboarding-steps"
import useSpotCoaching from "@/api/use-spot-coaching"
import { OnboardingSteps } from "@/components/onboarding/constants"
import {
    CARE_TEAM_EMAIL,
    MAX_WIDTH_MOBILE_MEDIA,
    ParticipantUrls,
    ServerStateKeys,
    SessionScheduleStatus
} from "@/constants"
import { useAuthContext, useStoreContext } from "@/context"
import { type IUseTranslation, type TUseLogoNavigation, useLogoNavigation, useTranslation } from "@/hooks"
import { CohortModalities } from "@/models/types"
import { LocalStorageServiceKeys, StorageService } from "@/services"
import ProductTypeLogo from "@/shared/ProductTypeLogo"
import Avatar from "@/shared/avatar/Avatar"
import Sidebar, { ESidebarTag } from "@/shared/sidebar/Sidebar"
import { BetaStatusTag } from "@/shared/tags/BetaStatusTag"
import type { TEmptyCallback } from "@/shared/types/functions"
import LiveNowSvg from "@/svgs/LiveNowSvg"
import { isIndidualCoachSession } from "@/utils/gates/session"

import "./styles.scss"

const storageService: StorageService = new StorageService()

interface IProps {
    tag?: ESidebarTag
    closedMenu?: boolean
    integrated?: boolean
}

const LeftSidebar: FC<IProps> = ({ tag = ESidebarTag.Div, closedMenu = false, integrated = false }: IProps) => {
    const { t }: IUseTranslation = useTranslation()
    const { logout, user } = useAuthContext()
    const { toggleBurgerMenu, isOpenBurgerMenu } = useStoreContext()
    const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const { pathname } = useLocation()
    const { data: sessions } = useCoachingSessions()
    const { data: productType } = useSpotCoaching()
    const { data: steps } = useOnboardingSteps()

    const queryClient: QueryClient = useQueryClient()

    const sidebarTogglerButtonRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        integrated && closedMenu && setTimeout(() => sidebarTogglerButtonRef?.current?.click(), 0)
    }, [integrated, closedMenu])

    const notScheduledSessions = useMemo(() => {
        return (
            sessions?.upcoming_sessions?.filter(
                session =>
                    !session.session_time &&
                    session.event_status === SessionScheduleStatus.NOT_SCHEDULED &&
                    (session.cohort_modality === CohortModalities.Individual || isIndidualCoachSession(session))
            ) ?? []
        )
    }, [sessions])

    const handleNavigation: TEmptyCallback = useCallback((): void => {
        if (isOpenBurgerMenu && isMobileView) {
            toggleBurgerMenu()
        }
    }, [isMobileView, isOpenBurgerMenu, toggleBurgerMenu])

    const hasDiagnosticeStep = steps?.previousSteps?.some(
        step => step === OnboardingSteps.DIAGNOSTIC_STEP || step === OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP
    )

    const isSurveysPageVisible: boolean = useMemo((): boolean => user?.hasSurveys, [user?.hasSurveys])

    const { handleLogoNavigation }: TUseLogoNavigation = useLogoNavigation()

    const lastViewedModuleId: number | null = storageService.getItem<number>(LocalStorageServiceKeys.LastViewedModule())

    return (
        <Sidebar
            tag={tag}
            sidebarLogo={<ProductTypeLogo handleLogoClick={handleLogoNavigation} />}
            closedMenu={integrated ? false : closedMenu}
            sidebarTogglerButtonRef={sidebarTogglerButtonRef}
        >
            {isMobileView && (
                <Avatar url={user?.photo} circleFillColor="#FFFFFF" width={45} height={45} className="mb-4 mx-auto" />
            )}
            <ul className="menu-capitalized no-margin">
                <li
                    tabIndex={pathname.includes(ERoutePath.DASHBOARD) ? -1 : 0}
                    className={classNames({
                        active: pathname.includes(ERoutePath.DASHBOARD),
                        hidden: !user?.modulesAvailable
                    })}
                >
                    <Link
                        to={
                            isEmpty(lastViewedModuleId)
                                ? ParticipantUrls.DASHBOARD
                                : `${ParticipantUrls.DASHBOARD}/${lastViewedModuleId}`
                        }
                        tabIndex={pathname.includes(ERoutePath.DASHBOARD) ? -1 : 0}
                        className={classNames(
                            "select-none",
                            pathname.includes(ERoutePath.DASHBOARD) && "pointer-events-none"
                        )}
                        onClick={async (): Promise<void> => {
                            handleNavigation()

                            if (pathname.includes(ERoutePath.CHAPTER)) {
                                if (!isEmpty(user.modules))
                                    await queryClient.invalidateQueries([
                                        ServerStateKeys.ModuleById,
                                        Number(user?.modules?.find(m => m?.rank === lastViewedModuleId)?.id)
                                    ])
                                await queryClient.invalidateQueries([ServerStateKeys.ModulesExcerpts])
                                await queryClient.invalidateQueries([ServerStateKeys.JourneyAchievements])
                            }
                        }}
                    >
                        <span>{t("Dashboard")}</span>
                    </Link>
                </li>

                <li className={classNames({ active: pathname.includes(ParticipantUrls.SESSION_LIST) })}>
                    <Link
                        to={ParticipantUrls.SESSION_LIST}
                        className="flex justify-center md:justify-start"
                        onClick={handleNavigation}
                    >
                        <span className={classNames({ "mr-2": sessions?.live_session || notScheduledSessions.length })}>
                            {productType?.sessionPageName}
                        </span>
                        {notScheduledSessions.length > 0 && !sessions?.live_session && <span className="red-dot" />}
                        {sessions?.live_session && <LiveNowSvg width={22} height={18} />}
                    </Link>
                </li>

                {isSurveysPageVisible && (
                    <li
                        tabIndex={0}
                        className={classNames("surveys-list-item", {
                            active: matchPath(ParticipantUrls.SURVEYS, pathname)
                        })}
                    >
                        <Link to={ParticipantUrls.SURVEYS} onClick={handleNavigation}>
                            <span>{t("participantSide.dashboard.sidebar.navigationList.surveys.label")}</span>
                        </Link>
                        {user?.hasNotCompletedSurveys && <span className="ml-1 red-dot" />}
                    </li>
                )}
                {hasDiagnosticeStep && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.DIAGNOSTIC) })}>
                        <Link to={ParticipantUrls.DIAGNOSTIC} onClick={handleNavigation}>
                            <span>{t("Diagnostic & Goals")}</span>
                        </Link>
                    </li>
                )}
                {isMobileView && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.PROFILE) })}>
                        <Link to={ParticipantUrls.PROFILE} onClick={handleNavigation}>
                            <span>{`${t("Profile")} & ${t("Settings")}`}</span>
                        </Link>
                    </li>
                )}
                {user?.availableCoachingPlan && (
                    <li className={classNames({ active: pathname.includes(ParticipantUrls.COACHING_PLAN) })}>
                        <Link to={ParticipantUrls.COACHING_PLAN} onClick={handleNavigation}>
                            <span>{t("Coaching Plan")}</span>
                        </Link>
                    </li>
                )}
                {user?.practiceLab && (
                    <li
                        className={classNames({
                            active:
                                matchPath(ParticipantUrls.COACHEES, pathname) ||
                                matchPath(ParticipantUrls.SIMULAB_CHAT, pathname)
                        })}
                    >
                        <Link to={ParticipantUrls.COACHEES} onClick={handleNavigation}>
                            <span className="d-flex align-items-center justify-content-center justify-content-md-start">
                                {t("Wiser SimuLab")} <BetaStatusTag className="ml-2" size="s" />
                            </span>
                        </Link>
                    </li>
                )}
                <li>
                    <a href={`mailto:${productType?.supportEmail}` || CARE_TEAM_EMAIL}>
                        <span>
                            {t("Contact")} {productType?.supportTeamName}
                        </span>
                    </a>
                </li>
                <li className="d-md-none">
                    <Link to="#" onClick={() => logout()}>
                        <span>{t("Sign out")}</span>
                    </Link>
                </li>
            </ul>
        </Sidebar>
    )
}

export default memo(LeftSidebar)
