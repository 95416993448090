import { forwardRef } from "react"

import classNames from "classnames"
import { useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"

interface IProps {
    label: string
    name: string
    isLastOption?: boolean
    isFirstOption?: boolean
    control: any
    value: string
    previousAnswer?: string
    id: string
    showLabel?: boolean
    isFocusOnLabel: boolean
}

const LikertLabel = forwardRef<HTMLLabelElement, IProps>(
    (
        {
            label,
            isLastOption,
            isFirstOption,
            name,
            control,
            value,
            showLabel = true,
            isFocusOnLabel,
            id,
            previousAnswer
        },
        ref
    ) => {
        const { t } = useTranslation()
        const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
        const answered = useWatch({ control, name })
        const isCurrentAnsweredOption = answered?.[name] === value
        const firstOrLastOption = (isFirstOption || isLastOption) && !answered?.[name]
        const isNotEmptyLabel = label.trim().length
        const show = showLabel && (!isMobileView || firstOrLastOption || isCurrentAnsweredOption)

        return (
            <label
                htmlFor={id}
                className={classNames("position-relative !outline-transparent", { outline: isFocusOnLabel })}
                aria-label={isNotEmptyLabel ? label : t("Definition scale")}
                ref={ref}
            >
                {show && isNotEmptyLabel ? <span className="label">{label}</span> : <></>}
                {previousAnswer && !answered && (
                    <>
                        <div className="likert-previous-answer-circle" />
                        <div
                            className={classNames("likert-previous-answer-vertical-line", {
                                withoutLabel: !(show && isNotEmptyLabel)
                            })}
                        />
                        <p
                            className={classNames("m-0 color-accent likert-previous-answer-text", {
                                right: isLastOption,
                                left: isFirstOption
                            })}
                        >
                            Your previous response
                        </p>
                    </>
                )}
            </label>
        )
    }
)

export default LikertLabel
