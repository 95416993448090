import { isEmpty } from "@/3514/utils"

function getScaleAnimationCssString(
    config: { identifier: string; scaleTo?: number; durationInMs?: number } = {
        identifier: String()
    }
): string {
    const defaultConfig = { scaleTo: 0.9, durationInMs: 250 }

    const renderConfig = { ...defaultConfig, ...config }

    return `
      .${renderConfig.identifier}-enter {
        opacity: 0;
        transform: scale(${renderConfig.scaleTo});
      }
      .${renderConfig.identifier}-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity ${renderConfig.durationInMs}ms, transform ${renderConfig.durationInMs}ms;
      }
      .${renderConfig.identifier}-exit {
        opacity: 1;
        transform: scale(1);
      }
      .${renderConfig.identifier}-exit-active {
        opacity: 0;
        transform: scale(${renderConfig.scaleTo});
        transition: opacity ${renderConfig.durationInMs}ms, transform ${renderConfig.durationInMs}ms;
      }
    `
}

function getSlideAnimationCssString(
    config: { identifier: string; translateYInPx?: number; durationInMs?: number } = {
        identifier: String()
    }
): string {
    const defaultConfig = { translateYInPx: 20, durationInMs: 250 }

    const renderConfig = { ...defaultConfig, ...config }

    return `
      .${renderConfig.identifier}-enter {
        opacity: 0;
        transform: translateY(${renderConfig.translateYInPx}px);
      }
      .${renderConfig.identifier}-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity ${renderConfig.durationInMs}ms, transform ${renderConfig.durationInMs}ms;
      }
      .${renderConfig.identifier}-exit {
        opacity: 1;
        transform: translateY(0);
      }
      .${renderConfig.identifier}-exit-active {
        opacity: 0;
        transform: translateY(${renderConfig.translateYInPx}px);
        transition: opacity ${renderConfig.durationInMs}ms, transform ${renderConfig.durationInMs}ms;
      }
    `
}

function getBlurAnimationCssString(
    config: { identifier: string; blurFrom?: number; durationInMs?: number } = {
        identifier: String()
    }
): string {
    const defaultConfig = { blurFrom: 100, durationInMs: 250 }

    const renderConfig = { ...defaultConfig, ...config }

    return `
      .${renderConfig.identifier}-enter {
        opacity: 0;
        filter: blur(${renderConfig.blurFrom}px);
      }
      .${renderConfig.identifier}-enter-active {
        opacity: 1;
        filter: blur(0);
        transition: opacity ${renderConfig.durationInMs}ms, filter ${renderConfig.durationInMs}ms;
      }
      .${renderConfig.identifier}-exit {
        opacity: 1;
        filter: blur(0);
      }
      .${renderConfig.identifier}-exit-active {
        opacity: 0;
        filter: blur(${renderConfig.blurFrom}px);
        transition: opacity ${renderConfig.durationInMs}ms, filter ${renderConfig.durationInMs}ms;
      }
    `
}

function getTransitionTwClasses(
    variant: "colors" | "all" | string = "all",
    durationInMs = 200,
    type: "ease-linear" | "ease-in" | "ease-out" | "ease-in-out" = "ease-in-out"
): string {
    return `transition-${variant} duration-${durationInMs} ${type}`
}

function getCssIdentifierFromFileName(fileName: string, postfix?: string): string {
    const name: string = fileName
        .replace(/([A-Z])/g, " $1")
        .trim()
        .split(" ")
        .map((word: string): string => word[0].toLowerCase())
        .join("")

    return isEmpty(postfix) ? name : `${name}-${postfix}`
}

export {
    getScaleAnimationCssString,
    getSlideAnimationCssString,
    getBlurAnimationCssString,
    getTransitionTwClasses,
    getCssIdentifierFromFileName
}
