enum ETextAreaVariant {
    Default = "default",
    LightGray = "lightGray"
}

enum ETextAreaScrollbarVariant {
    Transparent = "noScrollbar",
    Thin = "thin"
}

enum ETextAreaBorderWidth {
    Tiny = "tiny",
    Medium = "medium"
}

export { ETextAreaVariant, ETextAreaScrollbarVariant, ETextAreaBorderWidth }
