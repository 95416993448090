import { useCallback, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useTranslation } from "react-i18next"

import Heading from "$/components/Heading/Heading"
import { getErrorMessages } from "$/utils/get-error-messages"

import { Button, EButtonSize, EButtonVariant } from "@/3514/components"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { useSimulabContext } from "@/entities/simulab"
import { useEndConversation } from "@/entities/simulab/api"
import { QueryKey } from "@/entities/simulab/constants"
import Modal from "@/shared/modal"

import "../styles.scss"

export const SimulabEndConversationModal = () => {
    const queryClient = useQueryClient()
    const { t } = useTranslation()
    const { showEndConversationModal, conversation, setShowEndConversationModal } = useSimulabContext()
    const [error, setError] = useState(null)
    const endConversation = useEndConversation()

    const onEndConversationClick = useCallback(() => {
        endConversation.mutate(
            { conversationId: `${conversation?.id}` },
            {
                onSuccess() {
                    queryClient.invalidateQueries([QueryKey.SimulabConversation, { id: conversation?.id }])
                    setShowEndConversationModal(false)
                },
                onError(error: AxiosError) {
                    setError(getErrorMessages(error))
                }
            }
        )
    }, [conversation, setShowEndConversationModal, endConversation])

    const onCloseModal = useCallback(() => {
        setShowEndConversationModal(false)
    }, [setShowEndConversationModal])

    return (
        <Modal
            show={showEndConversationModal && conversation.status !== "completed"}
            onClose={onCloseModal}
            modalBodyClass="simulab-end-conversation-modal"
            headerContent={
                <div className="p-1">
                    <Heading tag="h2" className="text-center m-0" fontSize={36}>
                        {t("Congrats, You Finished D.I.G.!")}
                    </Heading>
                </div>
            }
        >
            <section className="simulab-end-conversation-modal__body">
                <p className="font-extrabold mb-20 text-center">
                    {t("We detected a ‘Plan’ Question. Are you done ‘Digging’?")}
                </p>
                <p className="mb-30 text-center">
                    {t(
                        "The SimuLab focuses exclusively on D.I.G., since that’s where we see the greatest opportunity to stimulate coachee growth (and encounter new challenges!). Ending the conversation will generate a feedback summary that provides analysis of your strengths and growth areas."
                    )}
                </p>
                <WarningMessage message={error} />
                <div className="mx-auto w-max mb-1">
                    <Button onClick={onEndConversationClick}>{t("Yes, End Conversation")}</Button>
                </div>
                <div className="mx-auto w-max">
                    <Button
                        variant={EButtonVariant.EmptyTransparent}
                        size={EButtonSize.Container}
                        onClick={onCloseModal}
                    >
                        <span className="color-accent fs-14">{t("No, I want to ask another D.I.G. question")}</span>
                    </Button>
                </div>
            </section>
        </Modal>
    )
}
