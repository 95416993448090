import { ReactNode } from "react"

import classNames from "classnames"

import { Button, EButtonSize, EButtonVariant } from "@/3514/components"
import { type IAuthContext, useAuthContext } from "@/context"
import { isParticipant } from "@/utils/gates/user"

type Props = {
    className?: string
    disabled?: boolean
    children: ReactNode
    onClick: () => void
}
export const BehavioralButton = ({ className, disabled = false, children, onClick }: Props) => {
    const { user }: IAuthContext = useAuthContext()

    const isCoachSide: boolean = !isParticipant(user)

    return (
        <Button
            variant={EButtonVariant.EmptyTransparent}
            size={EButtonSize.Container}
            className={className}
            onClick={onClick}
            isDisabled={disabled}
        >
            <div
                className={classNames(
                    "behavioral-btn__details d-flex align-items-center",
                    isCoachSide && "hover:!border-b-accentOld",
                    { hover: !disabled }
                )}
            >
                <div className={classNames("ico ico_plus fs-14", { active: !disabled })}>+</div>
                <span className="d-inline-block ml-1 fs-14">{children}</span>
            </div>
        </Button>
    )
}
