import { THEME } from "@/3514/config"

import { ETypographyAlignment, ETypographyColor, ETypographyFontWeight, ETypographySize } from "./typography.types"

const sizesMap: { [K in ETypographySize]: string } = {
    [ETypographySize.Tiny]: "text-[12px] leading-[18px]",
    [ETypographySize.ExtraSmall]: "text-[13px] leading-[20px]",
    [ETypographySize.Small]: "text-[14px] leading-[21px]",
    [ETypographySize.Medium]: "text-[16px] leading-[24px]",
    [ETypographySize.Heading2]: "text-[24px] leading-[36px]",
    [ETypographySize.Heading1]: "text-[36px] leading-[54px]"
}

const colorsMap: { [K in ETypographyColor]: string } = {
    [ETypographyColor.Accent]: "text-accent",
    [ETypographyColor.Black]: "text-gray-100",
    [ETypographyColor.Dark]: "text-gray-200",
    [ETypographyColor.DarkGray]: "text-gray-23",
    [ETypographyColor.LightGray]: "text-gray-500",
    [ETypographyColor.White]: "text-white",
    [ETypographyColor.Blue]: "text-blue-200",
    [ETypographyColor.Error]: "text-red-100"
}

const fontWeightsMap: { [K in ETypographyFontWeight]: string } = {
    [ETypographyFontWeight.Default]: `font-[${THEME.FONTS_WEIGHTS[300]}]`,
    [ETypographyFontWeight.Semibold]: `font-[${THEME.FONTS_WEIGHTS[600]}]`,
    [ETypographyFontWeight.Bold]: `font-[${THEME.FONTS_WEIGHTS[800]}]`
}

const alignmentMap: { [A in ETypographyAlignment]: string } = {
    [ETypographyAlignment.Left]: "text-left",
    [ETypographyAlignment.Center]: "text-center",
    [ETypographyAlignment.Right]: "text-right"
}

const lineHeightMap: { [S in ETypographySize]: number } = {
    [ETypographySize.Tiny]: THEME.LINE_HEIGHTS[18],
    [ETypographySize.ExtraSmall]: THEME.LINE_HEIGHTS[20],
    [ETypographySize.Small]: THEME.LINE_HEIGHTS[21],
    [ETypographySize.Medium]: THEME.LINE_HEIGHTS[24],
    [ETypographySize.Heading2]: THEME.LINE_HEIGHTS[36],
    [ETypographySize.Heading1]: THEME.LINE_HEIGHTS[54]
}

const typographyConfig = { sizesMap, colorsMap, fontWeightsMap, alignmentMap, lineHeightMap }

export { typographyConfig }
