import { FC } from "react"

import SimpleTextField from "$/components/SimpleTextField/SimpleTextField"

import { Button } from "@/3514/components"
import { ParticipantData } from "@/models/participant-data-coach"
import Modal from "@/shared/modal"
import { getTimeInRoleDuration } from "@/utils/common"

interface IProps {
    participant: ParticipantData
    show: boolean
    onClose: () => void
}

const ProfileModal: FC<IProps> = ({ participant, show, onClose }) => {
    return (
        <Modal show={show} onClose={onClose} modalBodyClass="profile-modal" disableBodyScroll>
            <h2 className="h2 font-extrabold">Participant Profile</h2>
            <div className="row mb-2">
                <div className="col-6">
                    <SimpleTextField text={participant?.firstName} />
                </div>
                <div className="col-6">
                    <SimpleTextField text={participant?.lastName} />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12">
                    <SimpleTextField text={participant?.jobTitle} />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-6">
                    <SimpleTextField text={participant?.department} />
                </div>
                <div className="col-6">
                    <SimpleTextField text={participant?.officeLocation} />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <div className="row">
                        <div className="col-3 pr-0">
                            <span className="font-extrabold">Time in Current Role:</span>
                        </div>
                        <div className="col pl-5">
                            <span className="">
                                {" "}
                                {participant ? getTimeInRoleDuration({ participant, capitalized: true }) : null}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-80">
                <div className="col-12">
                    <div className="row">
                        <div className="col-3 pr-0">
                            <span className="font-extrabold"># of Direct Reports:</span>
                        </div>
                        <div className="col pl-5">
                            <span className="">{participant?.directReportsNumber} Direct Reports</span>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className="h2 font-extrabold">Participant’s Manager Information</h2>
            <div className="row mb-2">
                <div className="col-6">
                    <SimpleTextField text={participant?.managerFirstName} />
                </div>
                <div className="col-6">
                    <SimpleTextField text={participant?.managerLastName} />
                </div>
            </div>
            <div className="row mb-50">
                <div className="col-12">
                    <SimpleTextField text={participant?.managerEmail} />
                </div>
            </div>
            <div className="text-center">
                <Button className="!bg-accentOld" isCentered onClick={onClose}>
                    Close
                </Button>
            </div>
        </Modal>
    )
}
export default ProfileModal
