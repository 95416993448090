import {
    type Dispatch,
    type FC,
    type ReactElement,
    type SetStateAction,
    startTransition,
    useCallback,
    useState
} from "react"

import classnames from "classnames"

import type { TEmptyCallback } from "@/shared/types/functions"

import { ECoachTableHeaderName } from "../types"

type TCoachTableHeaderColumn = {
    name: ECoachTableHeaderName
    title: string
    className?: string
    isSortingColumn?: boolean
    withSortDesc?: boolean
    withSortInc?: boolean
    withTextLeftAligned?: boolean
    withTextCenterAligned?: boolean
    withoutLeftPadding?: boolean
    isInvisible?: boolean
    isLargeTableCell?: boolean
}

type TCoachTableHeaderProps = {
    onSort?: (order: boolean, name?: ECoachTableHeaderName) => void
    columnHeaders: TCoachTableHeaderColumn[]
}

const TableHeaderColumnTitle: FC<{ title: string }> = ({ title }: { title: string }): ReactElement => (
    <span>{title}</span>
)

const TableHeaderColumnSortingTitle: FC<{
    title: string
    handleOnClick: TEmptyCallback
    isActive: boolean
}> = ({
    title,
    handleOnClick,
    isActive
}: {
    title: string
    handleOnClick: TEmptyCallback
    isActive: boolean
}): ReactElement => (
    <div className="cursor-pointer d-flex align-items-center" onClick={handleOnClick}>
        <TableHeaderColumnTitle title={title} />
        <svg className="svg-16 ml-2">
            {isActive ? (
                <path d="m 1,9.2071068 7,-7 7,7" fill="none" stroke="currentColor" strokeWidth="2" />
            ) : (
                <path d="M1 1.5L8 8.5L15 1.5" fill="none" stroke="currentColor" strokeWidth="2" />
            )}
        </svg>
    </div>
)

const CoachTableHeader: FC<TCoachTableHeaderProps> = ({
    onSort,
    columnHeaders
}: TCoachTableHeaderProps): ReactElement => {
    const [isSorted, setIsSorted]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const onHeaderSortClick: (name?: ECoachTableHeaderName) => void = useCallback(
        (name?: ECoachTableHeaderName): void =>
            startTransition((): void => (setIsSorted((prev: boolean): boolean => !prev), onSort?.(isSorted, name))),
        [isSorted, onSort]
    )

    return (
        <thead>
            <tr>
                {columnHeaders.map(
                    (c: TCoachTableHeaderColumn): ReactElement => (
                        <th
                            key={`coach-table-header-${c.title}`}
                            className={classnames({
                                sortable: c.isSortingColumn,
                                "d-lg-table-cell": c.isLargeTableCell,
                                "d-none": c.isInvisible,
                                "sort-desc": c.withSortDesc,
                                "text-left": c.withTextLeftAligned,
                                "text-center": c.withTextCenterAligned,
                                "sort-inc": c.withSortInc,
                                "pl-0": c.withoutLeftPadding,
                                [c.className]: c.className
                            })}
                        >
                            {c.isSortingColumn ? (
                                <TableHeaderColumnSortingTitle
                                    title={c.title}
                                    isActive={isSorted}
                                    handleOnClick={(): void => onHeaderSortClick(c.name)}
                                />
                            ) : (
                                <TableHeaderColumnTitle title={c.title} />
                            )}
                        </th>
                    )
                )}
            </tr>
        </thead>
    )
}

export { CoachTableHeader, type TCoachTableHeaderProps, type TCoachTableHeaderColumn }
