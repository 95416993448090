import classNames from "classnames"

import { getTransitionTwClasses } from "@/3514/utils"

import { ETextAreaBorderWidth, ETextAreaScrollbarVariant, ETextAreaVariant } from "./textarea.types"

const _baseClasses: string = classNames(
    ["bg-white", "w-full", "px-[15px]", "py-[10px]", "break-keep"],
    getTransitionTwClasses()
)

const borderWidthMap: { [R in ETextAreaBorderWidth]: string } = {
    [ETextAreaBorderWidth.Tiny]: "border-[1px]",
    [ETextAreaBorderWidth.Medium]: "border-[2px]"
}

const variantClassesMap: {
    [V in ETextAreaVariant]: () => string
} = {
    [ETextAreaVariant.Default]: (): string =>
        classNames(_baseClasses, [
            "border-blue-400",
            "hover:border-blue-300",
            "focus:border-blue-300",
            "active:border-blue-300"
        ]),
    [ETextAreaVariant.LightGray]: (): string =>
        classNames(_baseClasses, [
            "border-gray-400",
            "hover:border-gray-300",
            "focus:border-gray-300",
            "active:border-gray-300"
        ])
}

const scrollbarsClassesMap: { [V in ETextAreaScrollbarVariant]: (textareaColorVariant: ETextAreaVariant) => string } = {
    [ETextAreaScrollbarVariant.Transparent]: (_variant: ETextAreaVariant): string => "no-scrollbar",
    [ETextAreaScrollbarVariant.Thin]: (variant: ETextAreaVariant): string =>
        variant === ETextAreaVariant.LightGray ? "scrollbar-thin-gray" : "scrollbar-thin-blue"
}

const DEFAULT_MIN_LINES: number = 2

const DEFAULT_MAX_LINES: number = 8

const DEFAULT_BORDER_RADIUS: { tl?: number; tr?: number; br?: number; bl?: number } = {
    tl: 10,
    tr: 10,
    br: 10,
    bl: 10
}

const classes = { container: "flex flex-col gap-y-[5px] w-full", label: "hidden", error: "px-[10px]" }

const config = {
    variantClassesMap,
    borderWidthMap,
    scrollbarsClassesMap,
    DEFAULT_MIN_LINES,
    DEFAULT_MAX_LINES,
    DEFAULT_BORDER_RADIUS,
    classes
}

export { config as textareaConfig }
