import classNames from "classnames"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"

import { Button } from "@/3514/components"
import { SCHEDULE_STATUS_TEXT } from "@/constants"
import { ThreeWayAvatar } from "@/entities/three-way"
import Avatar from "@/shared/avatar/Avatar"

type Props = {
    className?: string
    session: any
    onScheduleClick: (session) => void
}

const ScheduleItem = ({ session, className = "", onScheduleClick }: Props) => {
    const { t } = useTranslation()
    const isExpired = DateTime.now().toMillis() > DateTime.fromSQL(session.scheduling_window_end).toMillis()

    const isThreeWay = session?.is_three_way_session

    return (
        <div className={classNames("schedule-item", className)}>
            <div className="schedule-item__group-image">
                {isThreeWay ? (
                    <ThreeWayAvatar url={session.coach.photo} alt="coach photo" width={35} height={35} />
                ) : (
                    <Avatar url={session.coach.photo} width={35} height={35} alt="coach photo" />
                )}
            </div>
            <div className="schedule-item__group-info">
                <span className="d-block fs-14">{session.session_type.name}</span>
                <span className="color-gray fs-14">
                    {session?.session_type?.duration} {t("min")}
                    {isThreeWay && ", "}
                </span>
                <span className="color-gray fs-14 d-block d-md-inline">{isThreeWay ? `${t("three-way")}` : ""}</span>
            </div>
            <div className="schedule-item__group-time">
                <span className="color-gray">
                    {isExpired ? t("Expired") : SCHEDULE_STATUS_TEXT[session.event_status]}
                </span>
            </div>
            <div className="d-flex align-items-center">
                <div className="schedule-item__group-action">
                    <Button onClick={() => onScheduleClick(session)}>{t("Schedule")}</Button>
                </div>
            </div>
        </div>
    )
}

export default ScheduleItem
