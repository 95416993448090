import { useState } from "react"

import { Link } from "react-router-dom"

import CronofyWidget from "$/components/CronofyWidgets/CronofyWidget"
import Heading from "$/components/Heading/Heading"
import { CRONOFY_WIDGET } from "$/constants"
import { http } from "$/http"
import { getErrorMessages } from "$/utils/get-error-messages"

import { Button } from "@/3514/components"
import Urls from "@/api/urls"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { type IAuthContext, useAuthContext } from "@/context"
import { useAvailabilityConfirmRules } from "@/entities/coach-calendar/api/use-confirm"
import { useOrderNavigationCalendar } from "@/entities/coach-calendar/hooks/use-order-navigation-calendar"
import { SimpleLayout } from "@/layouts"
import DateWidget from "@/shared/DateWidget"
import { FullSpinner } from "@/shared/spinner"

import { CoachCalendarStepUrls } from "./constants"
import { StepsNavigator } from "./ui/StepNavigator"

type Props = {
    order?: number
}

const ConfirmAvailabilityStep = ({ order }: Props) => {
    const { navigateByOrder } = useOrderNavigationCalendar(order)
    const { refetchUser }: IAuthContext = useAuthContext()
    const { data, isLoading, error: apiError } = useAvailabilityConfirmRules()
    const [error, setError] = useState(null)

    const onConfirmClick = async () => {
        try {
            await http.post(Urls.confirmSetupCalendar())
            refetchUser()
            navigateByOrder()
        } catch (error) {
            setError(getErrorMessages(error))
        }
    }

    const config = {
        ...(data ?? {}),
        config: { ...data?.config, interval: 15 },
        callback: () => {}
    }

    return (
        <SimpleLayout
            header={<DateWidget className="coach-date-title" />}
            headerVariant="gray"
            showBackBtn
            showProfilePhoto
            className="coach-confirm-availability-layout"
        >
            <div className="coach-calendar-wrapper mx-auto pb-5">
                <div className="d-flex justify-content-center mt-5">
                    <StepsNavigator activeStep={CoachCalendarStepUrls.AVAILABILITY_CONFIRM_STEP} />
                </div>
                <Heading textAlign="center" tag="h6" fontSize={16} className="mb-40 mt-5 text-accentOld">
                    Step {order}
                </Heading>
                <Heading tag="h1" textAlign="center" className="mb-2" fontSize={36}>
                    Confirm Your Availability
                </Heading>

                <p className="mb-0 color-gray fs-14 text-center">
                    <span className="font-extrabold">You must confirm</span> or else your schedule will not be saved.
                    Check to make sure that everything looks right.
                </p>

                <p className="mb-1 color-gray fs-14 text-center">
                    This is the availability that will be visible to your 1:1 participants, as well as our Wiser Care
                    Team.
                </p>

                <WarningMessage message={error || getErrorMessages(apiError?.response?.data)?.join("")} />
                <div className="mx-auto flex justify-center mt-50 mb-3">
                    <Button className="!bg-accentOld" onClick={onConfirmClick}>
                        Confirm & Save
                    </Button>
                </div>

                <p className="text-center color-gray fs-14 mb-40">
                    <span className="text-accentOld font-extrabold">
                        <Link to={CoachCalendarStepUrls.AVAILABILITY_STEP}>Back to Edit</Link>
                    </span>
                </p>

                {isLoading ? (
                    <FullSpinner />
                ) : (
                    <CronofyWidget widget={CRONOFY_WIDGET.AvailabilityViewer} id="av-viewer" options={config} />
                )}
            </div>
        </SimpleLayout>
    )
}

export default ConfirmAvailabilityStep
