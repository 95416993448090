import { FC, SVGProps, memo } from "react"

import classNames from "classnames"

interface IProps extends SVGProps<SVGSVGElement> {
    isOpen?: boolean
}

const SideMenuSvg: FC<IProps> = ({ height = 21, width = 31, className = "", ...rest }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 31 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
            className={classNames({ [className]: className })}
        >
            <path d="M1 4.92175H16" stroke="#F3F3F3" strokeWidth="2" strokeLinecap="square" />
            <path d="M1 4.92175H16" stroke="url(#paint0_linear_1801_30117)" strokeWidth="2" strokeLinecap="square" />
            <path d="M1 10.4H16" stroke="#F3F3F3" strokeWidth="2" strokeLinecap="square" />
            <path d="M1 10.4H16" stroke="url(#paint1_linear_1801_30117)" strokeWidth="2" strokeLinecap="square" />
            <path d="M1 15.8783H16" stroke="#F3F3F3" strokeWidth="2" strokeLinecap="square" />
            <path d="M1 15.8783H16" stroke="url(#paint2_linear_1801_30117)" strokeWidth="2" strokeLinecap="square" />
            <path d="M22 17.4L29 10.4L22 3.40002" stroke="#F3F3F3" strokeWidth="2.5" />
            <path d="M22 17.4L29 10.4L22 3.40002" stroke="url(#paint3_linear_1801_30117)" strokeWidth="2.5" />
            <defs>
                <linearGradient
                    id="paint0_linear_1801_30117"
                    x1="15.3676"
                    y1="5.42176"
                    x2="1"
                    y2="5.42176"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0404CB" stopOpacity="0" />
                    <stop offset="0.0001" stopColor="#0404CB" />
                    <stop offset="1" stopColor="#0404CB" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1801_30117"
                    x1="15.3676"
                    y1="10.9"
                    x2="1"
                    y2="10.9"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0404CB" stopOpacity="0" />
                    <stop offset="0.0001" stopColor="#0404CB" />
                    <stop offset="1" stopColor="#0404CB" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1801_30117"
                    x1="15.3676"
                    y1="16.3783"
                    x2="1"
                    y2="16.3783"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0404CB" stopOpacity="0" />
                    <stop offset="0.0001" stopColor="#0404CB" />
                    <stop offset="1" stopColor="#0404CB" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1801_30117"
                    x1="25.5"
                    y1="3.99029"
                    x2="25.5"
                    y2="17.4"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0404CB" stopOpacity="0" />
                    <stop offset="0.0001" stopColor="#0404CB" />
                    <stop offset="1" stopColor="#0404CB" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default memo(SideMenuSvg)
