import { type ReactNode } from "react"

interface ISelectOption {
    id: string
    label: string
    value: string | ReactNode
    isDisabled?: boolean
}

enum ESelectVariant {
    Default = "default"
}

enum ESelectSize {
    Medium = "medium",
    Full = "full"
}

export { type ISelectOption, ESelectVariant, ESelectSize }
