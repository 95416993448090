import type { FC, ReactElement } from "react"

import classNames from "classnames"

type TProps = { className?: string }

const Image: FC<TProps> = ({ className }: TProps): ReactElement => (
    <svg
        className={classNames({ [className]: className })}
        width="154"
        height="154"
        viewBox="0 0 154 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M89.5407 50.466L93.2575 71.2106C94.4185 77.6905 100.613 82.0024 107.093 80.8414C107.918 80.6935 108.726 80.4589 109.502 80.1415L108.257 80.6505C117.659 76.806 124.45 68.4303 126.267 58.4366C128.551 45.8804 129.467 38.356 129.016 35.8635C128.726 34.2611 128.411 32.6544 128.072 31.0434C127.626 28.9214 125.544 27.5631 123.422 28.0095C122.959 28.1068 122.518 28.2869 122.119 28.541L90.2423 48.8647C89.7055 49.2069 89.4284 49.8393 89.5407 50.466Z"
            fill="#DA4200"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9579 72.1948L61.0323 72.6221C67.6141 72.7556 72.8416 78.1994 72.7081 84.7813C72.6911 85.6193 72.5858 86.4532 72.3938 87.2692L69.0186 101.617C47.6239 109.593 35.1886 113.542 31.7127 113.465C30.0843 113.429 28.4478 113.369 26.8032 113.287C24.6375 113.177 22.9703 111.333 23.0795 109.168C23.1032 108.696 23.2121 108.232 23.4007 107.798L38.486 73.1383C38.74 72.5545 39.3214 72.1819 39.9579 72.1948Z"
            fill="#DA4200"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.4531 125.52L79.6248 139.778C80.1286 140.484 81.1094 140.648 81.8155 140.144C82.2011 139.869 82.442 139.435 82.471 138.962L82.8707 132.434L83.2061 126.957L83.3738 124.219L76.4973 123.5L69.4531 125.52Z"
            fill="#FEA57F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.6933 121.259L95.2866 123.492L98.4734 127.959L102.272 133.283C102.775 133.989 103.756 134.154 104.462 133.65C104.848 133.375 105.089 132.94 105.118 132.467L106.188 114.986L99.1441 117.006L93.6933 121.259Z"
            fill="#FEA57F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.055 28.6888L49.2509 31.1182C57.659 31.8178 66.0326 29.4168 72.7923 24.3678L96.3805 6.74945C97.2491 6.10063 98.4793 6.27886 99.1281 7.14752C99.4243 7.54408 99.5598 8.0379 99.5074 8.53009L99.0931 12.422C98.5855 17.1911 99.0042 22.0134 100.326 26.6236L102.328 33.6042L110.334 61.5264L115.902 80.9445C121.671 101.062 110.039 122.047 89.9213 127.816C69.8036 133.584 48.8186 121.952 43.05 101.835L37.4819 82.4165L29.4753 54.4943L27.4737 47.5137C26.1533 42.9089 23.9036 38.6233 20.8634 34.9212L18.3751 31.8911C17.687 31.0532 17.8084 29.8162 18.6463 29.1281C19.0409 28.804 19.5462 28.6465 20.055 28.6888Z"
            fill="#FE4D00"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.5253 80.4929L77.0512 80.0554C88.4085 76.7987 100.256 83.3656 103.512 94.723C106.769 106.08 100.202 117.927 88.8446 121.184L87.3187 121.622C75.9613 124.878 64.1143 118.311 60.8577 106.954C57.601 95.5966 64.1679 83.7496 75.5253 80.4929Z"
            fill="#FEA57F"
            stroke="#FEA57F"
            strokeWidth="3.14106"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M82.933 50.97C86.0142 51.1315 95.5343 54.3751 95.6696 51.794C96.0383 44.7579 90.6312 38.7551 83.5925 38.3862C76.5537 38.0173 70.5487 43.4221 70.18 50.4581C69.8112 57.4941 79.8518 50.8085 82.933 50.97Z"
            fill="#FFDCCE"
        />
        <path
            opacity="0.9"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M83.0416 51.1327C86.6151 51.32 89.4486 52.6835 89.5121 51.4718C89.6993 47.8997 86.9541 44.8521 83.3806 44.6648C79.8071 44.4775 76.7584 47.2215 76.5712 50.7936C76.384 54.3657 79.4681 50.9454 83.0416 51.1327Z"
            fill="#071C41"
        />
        <path
            d="M78.6811 48.0437C79.764 48.1005 80.6878 47.269 80.7445 46.1865C80.8013 45.104 79.9694 44.1805 78.8865 44.1238C77.8036 44.067 76.8798 44.8985 76.8231 45.981C76.7663 47.0634 77.5982 47.987 78.6811 48.0437Z"
            fill="#FFDCCE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.9062 59.0066C52.3788 60.7763 46.0241 68.572 44.5416 66.4547C40.5004 60.6833 41.9048 52.7272 47.6786 48.6844C53.4523 44.6416 61.4089 46.043 65.4501 51.8144C69.4913 57.5859 57.4337 57.2369 54.9062 59.0066Z"
            fill="#FFDCCE"
        />
        <path
            opacity="0.9"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.8996 59.2015C51.9683 61.2541 50.288 63.9119 49.592 62.9179C47.5403 59.9878 48.2534 55.9486 51.1846 53.8961C54.1159 51.8436 58.1554 52.555 60.2071 55.4852C62.2588 58.4153 57.8309 57.149 54.8996 59.2015Z"
            fill="#071C41"
        />
        <path
            d="M50.2838 57.6444C49.3955 58.2663 48.1714 58.0507 47.5497 57.1628C46.9279 56.2749 47.144 55.0509 48.0323 54.4289C48.9205 53.8069 50.1446 54.0225 50.7664 54.9105C51.3881 55.7984 51.172 57.0224 50.2838 57.6444Z"
            fill="#FFDCCE"
        />
        <path
            d="M71.39 108.059L77.8351 111.28C78.4186 111.571 79.1282 111.368 79.4686 110.811L82.6258 105.648C82.9661 105.092 83.6758 104.888 84.2593 105.18L89.6726 107.885C90.2562 108.177 90.9658 107.973 91.3061 107.417L95.0651 101.27"
            stroke="#FE4D00"
            strokeWidth="3.14106"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M68.4681 97.8713L74.9132 101.092C75.4967 101.384 76.2064 101.18 76.5467 100.624L79.7039 95.4609C80.0443 94.9044 80.7539 94.7009 81.3374 94.9925L86.7508 97.6976C87.3343 97.9892 88.0439 97.7858 88.3843 97.2292L92.1433 91.0825"
            stroke="#FE4D00"
            strokeWidth="3.14106"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.7566 68.7547L78.922 64.7016C79.3982 63.8103 79.0647 62.7016 78.1756 62.221L76.9828 61.5762C76.7171 61.4325 76.4056 61.3987 76.1152 61.482L66.0861 64.3578C65.7892 64.4429 65.5388 64.6434 65.3907 64.9145L64.7613 66.0675C64.2804 66.9483 64.5806 68.0517 65.4415 68.5674L69.2325 70.8386C71.7862 72.3684 75.0965 71.5385 76.6263 68.9848C76.6716 68.9092 76.7151 68.8325 76.7566 68.7547Z"
            fill="#1F2E4F"
            fillOpacity="0.260899"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.7894 60.9087C66.7955 60.8877 66.8016 60.8666 66.8077 60.8452C67.4321 58.6465 69.7207 57.3704 71.9193 57.9948C72.5522 58.1746 73.1331 58.5026 73.6139 58.9518C74.3313 59.622 75.2744 60.2151 76.443 60.731C76.8323 60.9029 77.0086 61.3578 76.8368 61.7471C76.7644 61.911 76.6372 62.0446 76.4771 62.1248C75.438 62.6455 74.455 63.2713 73.5435 63.9923L72.2355 65.0271C71.9465 65.2557 71.5789 65.3611 71.2126 65.3204L69.5551 65.136C68.4 65.0075 67.2347 64.9978 66.0777 65.1068C65.6667 65.1455 65.3022 64.8438 65.2634 64.4328C65.2473 64.2618 65.2906 64.0905 65.386 63.9476C65.9948 63.036 66.4626 62.0231 66.7894 60.9087Z"
            fill="#FFDCCE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.7455 68.6973C77.6936 68.8108 77.6422 68.9308 77.5912 69.0572C76.3984 72.0152 73.0335 73.4461 70.0755 72.2533C69.5964 72.0601 69.1454 71.8035 68.7346 71.4904C68.6632 71.4359 68.5948 71.3858 68.5293 71.34C67.9392 70.9267 66.8661 70.364 65.31 69.6516C65.0229 69.5202 64.8967 69.181 65.0281 68.8939C65.1432 68.6424 65.4219 68.5094 65.6898 68.578L71.0691 69.956C72.4187 70.3017 73.8497 69.8913 74.811 68.883L78.6428 64.8638C78.8625 64.6333 79.2275 64.6246 79.4579 64.8443C79.6585 65.0355 79.6947 65.3423 79.5441 65.5749C78.6995 66.8801 78.0999 67.9208 77.7455 68.6973Z"
            fill="#FFDCCE"
        />
    </svg>
)

Image.displayName = "OwlHappyImage"

export { Image as OwlHappyImage }
