import { type ForwardRefExoticComponent, type ForwardedRef, Fragment, type ReactElement, forwardRef } from "react"

import classNames from "classnames"

import { type IUseTranslation, useTranslation } from "@/hooks"

import { CheckMarkIcon } from "../../assets"
import { ETypographyColor, ETypographySize, ETypographyTag, Typography } from "../typography"

type TProps = { isCompleted?: boolean; isRequired?: boolean; wrapperClassName?: string }

const classes: { wrapper: string; holder: string; markWrapper: string; mark: string } = {
    wrapper: "absolute top-auto left-auto right-0 bottom-0 flex items-center",
    holder: "pt-[4px] pr-[12px] pb-[6px] pl-[5px] flex items-center gap-x-[3px] select-none",
    markWrapper: "flex items-center justify-center w-[18px] h-[18px]",
    mark: "border-gray-23 border-dashed border-[1px] rounded-full h-[10px] w-[10px]"
}

const Component: ForwardRefExoticComponent<TProps> = forwardRef(
    (
        { isCompleted, isRequired = false, wrapperClassName = String() }: TProps,
        ref: ForwardedRef<HTMLDivElement>
    ): ReactElement => {
        const { t }: IUseTranslation = useTranslation()

        return (
            <div
                ref={ref}
                className={classNames(classes.wrapper, {
                    [wrapperClassName]: wrapperClassName
                })}
            >
                <div className={classes.holder}>
                    {isCompleted ? (
                        <Fragment>
                            <CheckMarkIcon />
                            <Typography
                                text={t("Completed")}
                                tag={ETypographyTag.Span}
                                color={ETypographyColor.DarkGray}
                                size={ETypographySize.Tiny}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className={classes.markWrapper}>
                                <i className={classes.mark} />
                            </div>
                            <Typography
                                text={isRequired ? t("common.required") : t("Incomplete")}
                                isItalic
                                tag={ETypographyTag.Span}
                                color={ETypographyColor.DarkGray}
                                size={ETypographySize.Tiny}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        )
    }
)

Component.displayName = "ChapterComponentCompletionMark"

export { Component as ChapterComponentCompletionMark }
