import { useMemo } from "react"

import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { isNullOrUndefined } from "$/utils/gates"

import { Button, EButtonSize, EButtonVariant } from "@/3514/components"
import { useAuthContext } from "@/context"
import { SimulabCoachee, SimulabQueryKey, useCreateSimulabConversation } from "@/entities/simulab"
import { COACHEE_CONVERSATION_COMPLETETION_STATUS } from "@/entities/simulab/constants"
import Avatar from "@/shared/avatar/Avatar"
import HtmlContent from "@/shared/html-content/HtmlContent"
import { CompleteCheckMarkSvg } from "@/svgs"
import { ReloadVectorSvg } from "@/svgs/ReloadIconSvg"

import "./styles.scss"

type Props = {
    coachee: SimulabCoachee
    className?: string
}

export const CoacheeCard = ({ coachee, className = "" }: Props) => {
    const queryClient = useQueryClient()
    const { user } = useAuthContext()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const createConversation = useCreateSimulabConversation()

    const conversation = useMemo(() => {
        return coachee.conversations.find(conversation => !!conversation)
    }, [coachee])

    const onClick = async () => {
        if (conversation) {
            navigate(`/simulab/${conversation.id}`)
            return
        }

        createConversation.mutate(
            { coacheeId: coachee.id, participantId: user.id },
            {
                onSuccess({ data }) {
                    navigate(`/simulab/${data.id}`)
                    queryClient.invalidateQueries([SimulabQueryKey.Coachees])
                }
            }
        )
    }

    const isCompleted = conversation?.status === COACHEE_CONVERSATION_COMPLETETION_STATUS.COMPLETED

    return (
        <article
            className={classNames("coachee-card", { "show-btn": !!conversation && !isCompleted }, className)}
            onClick={onClick}
            data-testid="coachee-card"
        >
            <header className="coachee-card__header">
                <Avatar className="coachee-card__avatar" url={coachee.photo} width={75} height={75} />
                <h2 className="coachee-card__name">{coachee.name}</h2>
                <HtmlContent content={coachee.summary} className="coachee-card__title" />
            </header>

            <HtmlContent className="coachee-card__description" content={coachee.description} />

            {isCompleted && (
                <p className={classNames("coachee-card__completed-conversation p-1 fs-14 color-gray font-extrabold")}>
                    <CompleteCheckMarkSvg variant="dark" height={20} width={20} />
                    <span>{t("Complete")}</span>
                </p>
            )}
            {!isCompleted && !isNullOrUndefined(conversation) && (
                <Button
                    variant={EButtonVariant.EmptyTransparent}
                    size={EButtonSize.Container}
                    className={classNames("coachee-card__reload-btn")}
                >
                    <ReloadVectorSvg className="coachee-card__reload-icon" />
                    {t("Resume Conversation")}
                </Button>
            )}
        </article>
    )
}
