import { ReactNode } from "react"

import classNames from "classnames"

import "./styles.scss"

type Props = {
    active: boolean
    text: ReactNode
    textColor?: string
    chevronColor?: string
    className?: string
    onToggle: () => void
}

const LabelDropdown = ({
    active,
    text,
    textColor = String(),
    chevronColor = "#FD4D00",
    onToggle,
    className = ""
}: Props) => {
    return (
        <div
            className={classNames("dropdown-toggler d-flex align-items-center cursor-pointer", {
                active,
                [className]: className
            })}
            onClick={onToggle}
        >
            <p className={classNames("font-sm m-0", { [textColor]: textColor })}>{text}</p>
            <svg
                className="ml-2"
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.75 6.625L6.5 2.375L2.25 6.625"
                    stroke={chevronColor}
                    strokeWidth="2"
                    strokeLinecap="square"
                />
            </svg>
        </div>
    )
}

export default LabelDropdown
