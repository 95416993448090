import {
    type FC,
    type LazyExoticComponent,
    type NamedExoticComponent,
    type ReactElement,
    Suspense,
    lazy,
    memo,
    startTransition,
    useCallback,
    useMemo
} from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import { fetchRespondentSurvey } from "@/entities/survey/api/use-respondent-survey"
import { ServerStateKeys } from "@/entities/survey/constants"
import { emptyAsyncCallback } from "@/shared/types/functions"

import { type TSurveysQuery, useSurveysQuery } from "../api"

import type { THandleListItemClickFunction } from "./types"
import { surveysPageUiUtils as utils } from "./utils"

import {
    SurveysAttentionBox as AttentionBox,
    SurveysFallbackSpinner as Spinner,
    type TSurveysDataListProps as TListProps
} from "."

const List: LazyExoticComponent<FC<TListProps>> = lazy(
    (): Promise<{ default: FC<TListProps> }> => import("./SurveysDataList")
)

const Container: FC = (): ReactElement => {
    const { data: surveysData, isFetching: isSurveysDataFetching }: TSurveysQuery = useSurveysQuery()

    const navigate: NavigateFunction = useNavigate()

    const queryClient: QueryClient = useQueryClient()

    const handleListItemClick: THandleListItemClickFunction = useCallback(
        async (accessCode: string): Promise<void> =>
            accessCode
                ? (await queryClient.prefetchQuery([ServerStateKeys.RespondentSurvey, accessCode], () =>
                      fetchRespondentSurvey(accessCode)
                  ),
                  startTransition((): void =>
                      navigate(`/survey/${accessCode}/`, {
                          state: { shouldBackButtonRedirectToSurveysPage: true }
                      })
                  ))
                : emptyAsyncCallback(),
        [queryClient, navigate]
    )

    const isAttentionBoxVisible: boolean = useMemo(
        (): boolean => utils.getIsAttentionBoxVisible(surveysData?.results ?? []),
        [surveysData]
    )

    return (
        <section className="surveys__container">
            <div className="surveys__wrapper">
                {isAttentionBoxVisible && (
                    <>
                        <AttentionBox />
                        <hr className="separator" />
                    </>
                )}

                <Suspense fallback={<Spinner />}>
                    {isSurveysDataFetching || !surveysData?.results?.length ? (
                        <Spinner />
                    ) : (
                        <List surveysData={surveysData.results} handleListItemClick={handleListItemClick} />
                    )}
                </Suspense>
            </div>
        </section>
    )
}

const SurveysContainer: NamedExoticComponent = memo(Container)

export { SurveysContainer }
