import { useState } from "react"

import { FormProvider } from "react-hook-form"

import Heading from "$/components/Heading/Heading"
import { getErrorMessages } from "$/utils/get-error-messages"

import { Button } from "@/3514/components"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { CalendarAccounts, CalendarPrimary, CalendarSyncAvailability } from "@/entities/coach-calendar"
import { useCalendarLinkSetting } from "@/entities/coach-calendar/hooks/use-calendar-link-setting"
import { CoachLayout } from "@/layouts"
import DateWidget from "@/shared/DateWidget"

import LeftSidebar from "./ui/LeftSidebar"

import "./styles.scss"

const CoachCalendars = () => {
    const { api, onSubmit, hookForm: methods } = useCalendarLinkSetting()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onSaveClick = async values => {
        setLoading(true)
        try {
            await onSubmit(values)
            api.refetch()
        } catch (error) {
            setError(getErrorMessages(error))
        } finally {
            setLoading(false)
        }
    }

    const errorMsg = [
        methods?.formState?.errors?.calendars?.message,
        methods?.formState?.errors?.primaryCalendar?.message
    ]
        .filter(Boolean)
        .join(", ")

    const isDirtyFields = methods.formState.isDirty

    return (
        <CoachLayout
            leftSidebar={<LeftSidebar />}
            headerTitle={<DateWidget className="coach-date-title" />}
            fullWidth
            withContainer={false}
            align="left"
        >
            <div className="coach-calendars-wrapper coach-calendars">
                <Heading tag="h1" className="mb-2 mt-50" fontSize={36}>
                    My Calendars
                </Heading>
                <p className="mb-1 color-gray fs-14">
                    Choose which calendar(s) you’d like to sync to Wiser to inform your availability.
                </p>
                <p className="color-gray fs-14 mb-50">
                    You will be able to manually tweak your availability later as needed.
                </p>
                <CalendarAccounts />

                <Heading tag="h4" textAlign="left" className="mb-2 mt-50" fontSize={16}>
                    Sync Availability with Sub-calendars
                </Heading>
                <p className="color-gray fs-14 mb-4">
                    Each of your calendars likely has sub-calendars which you may or may not want to use to inform your
                    availability. Common examples of sub-calendars include: work, personal, and national holidays.
                    Choose which sub-calendars to sync below.
                </p>
                <FormProvider {...methods}>
                    <CalendarSyncAvailability data={api?.data} />

                    <Heading tag="h4" textAlign="left" className="mb-2 mt-50" fontSize={16}>
                        Select a Primary Calendar
                    </Heading>
                    <p className="color-gray fs-14 mb-0">
                        Your primary calendar will be used to send Wiser session invites to your participants.
                    </p>
                    <p className="color-gray fs-14 mb-4">
                        This is the calendar that any Wiser-related events will appear on.
                    </p>
                    <CalendarPrimary data={api?.data} />
                </FormProvider>
                <div className="mt-50">
                    <WarningMessage message={error || errorMsg} />
                    <Button
                        {...(isDirtyFields && { className: "!bg-accentOld" })}
                        onClick={methods.handleSubmit(onSaveClick)}
                        isBusy={loading}
                        isDisabled={!isDirtyFields}
                    >
                        Save all changes
                    </Button>
                </div>
            </div>
        </CoachLayout>
    )
}

export default CoachCalendars
