import type { ReactNode } from "react"

import { THEME } from "@/3514/config"
import { ESessionAttendanceStatus } from "@/3514/pages/participant-dashboard/models"

import { ESpecialChar, SpecialChars } from "../special-char"

const symbolsMap: { [A in ESessionAttendanceStatus]: ReactNode } = {
    [ESessionAttendanceStatus.Present]: (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.65625 3.09306L2.70082 5.3235L7.34755 0.676758"
                stroke={THEME.COLORS.black}
                strokeWidth="0.9"
                strokeLinecap="square"
            />
        </svg>
    ),
    [ESessionAttendanceStatus.Accepted]: (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.65625 3.09306L2.70082 5.3235L7.34755 0.676758"
                stroke="#6F6F6F"
                strokeWidth="0.9"
                strokeLinecap="square"
            />
        </svg>
    ),
    [ESessionAttendanceStatus.Pending]: SpecialChars[ESpecialChar.QuestionMark],
    [ESessionAttendanceStatus.Tentative]: SpecialChars[ESpecialChar.QuestionMark],
    [ESessionAttendanceStatus.NotPresent]: SpecialChars[ESpecialChar.Cross],
    [ESessionAttendanceStatus.Declined]: SpecialChars[ESpecialChar.Cross]
}

const config = { symbolsMap }

export { config as rsvpMarkConfig }
