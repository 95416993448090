import { type FC, type LazyExoticComponent, type ReactElement, lazy } from "react"

import { type AxiosError } from "axios"
import classNames from "classnames"

import Heading from "$/components/Heading/Heading"
import { isEmptyString, isNullOrUndefined } from "$/utils/gates"

import { Button, EButtonVariant } from "@/3514/components"
import { type IUseTranslation, useTranslation } from "@/hooks"
import type { IAvailableGuestSessionModel } from "@/pages/swap-session/api"
import Modal, { type IModalProps } from "@/shared/modal"
import AttentionNotificationBox from "@/shared/notification/AttentionNotificationBox"

import type { IOriginalSession } from "./types"

import { SwapSessionActionArrow as ActionArrow, type TSwapSessionSessionBlockProps as TSessionBlockProps } from "."

type TSwapSessionConfirmSwapModalSubmitFunction = ({
    suggestedSessionId
}: {
    suggestedSessionId: number
}) => Promise<void>

type TSwapSessionConfirmSwapModalProps = IModalProps & {
    sessionOriginal: IOriginalSession
    sessionSuggested: IAvailableGuestSessionModel
    isSessionConfirmed: boolean
    mutationError?: AxiosError<{ message?: string; mailto?: { email?: string; title?: string } }>
    handleSubmit: TSwapSessionConfirmSwapModalSubmitFunction
    isMutationLoading?: boolean
    isMutationSuccessful?: boolean
}

const SessionBlock: LazyExoticComponent<FC<TSessionBlockProps>> = lazy(() => import("./SwapSessionSessionBlock"))

const SwapSessionConfirmSwapModal: FC<TSwapSessionConfirmSwapModalProps> = ({
    show,
    onClose,
    sessionOriginal,
    sessionSuggested,
    isSessionConfirmed,
    mutationError,
    handleSubmit,
    isMutationLoading,
    isMutationSuccessful
}: TSwapSessionConfirmSwapModalProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <Modal
            disableBodyScroll
            show={show}
            onClose={onClose}
            modalBodyClass="swap-session__modal"
            headerContent={
                <Heading tag="h2" className="text-center">
                    {!isSessionConfirmed
                        ? t("participantSide.swapSessionsScreen.swapModal.title")
                        : t("participantSide.swapSessionsScreen.swapModal.confirmedTitle")}
                </Heading>
            }
        >
            <div
                className={classNames("swap-session__modal__container", {
                    "with-message": isSessionConfirmed || !isNullOrUndefined(mutationError)
                })}
            >
                <div
                    className={classNames("swap-session__modal__content", {
                        "pt-0": isSessionConfirmed || !isNullOrUndefined(mutationError)
                    })}
                >
                    <div
                        className={classNames("swap-session__modal__content__message", {
                            "d-none": !isSessionConfirmed && !!isNullOrUndefined(mutationError)
                        })}
                    >
                        {isSessionConfirmed && (
                            <div className="swap-session__modal__content__message__mark">
                                <i />
                            </div>
                        )}
                        <div>
                            {isSessionConfirmed ? (
                                <span className="font-extrabold">
                                    {t("participantSide.swapSessionsScreen.swapModal.confirmedMessage")}
                                </span>
                            ) : (
                                <AttentionNotificationBox>
                                    <span className="font-extrabold">Uh oh!&nbsp;</span>

                                    {!isEmptyString(mutationError?.response?.data?.message) && (
                                        <>
                                            <span>{mutationError?.response?.data?.message}</span>
                                            &nbsp;
                                        </>
                                    )}

                                    {!isEmptyString(mutationError?.response?.data?.mailto?.email) &&
                                        !isEmptyString(mutationError?.response?.data?.mailto?.title) && (
                                            <a
                                                href={`mailto:${mutationError.response.data.mailto.email}`}
                                                className="color-brand font-extrabold cursor-pointer"
                                            >
                                                {t("Contact")}&nbsp;{mutationError.response.data.mailto.title}
                                            </a>
                                        )}
                                </AttentionNotificationBox>
                            )}
                        </div>
                    </div>

                    <div className="swap-session__modal__content__sessions">
                        <SessionBlock
                            coachPhoto={sessionOriginal?.coach?.photo}
                            coachName={sessionOriginal?.coach?.first_name}
                            coachLastName={sessionOriginal?.coach?.last_name}
                            sessionTime={sessionOriginal?.session_time}
                        />

                        <div className="swap-session__modal__content__sessions__arrow-wrapper">
                            <ActionArrow color="black" />
                        </div>

                        <SessionBlock
                            coachPhoto={sessionSuggested?.coachPhoto}
                            coachName={sessionSuggested.coachName}
                            sessionTime={sessionSuggested?.startTime}
                        />
                    </div>

                    <Button
                        variant={isSessionConfirmed ? EButtonVariant.Outlined : EButtonVariant.Solid}
                        onClick={(): Promise<void> => handleSubmit({ suggestedSessionId: sessionSuggested.id })}
                        isBusy={isMutationLoading}
                        isDisabled={
                            !isNullOrUndefined(mutationError)
                                ? true
                                : isMutationSuccessful && isSessionConfirmed
                                  ? false
                                  : !isMutationSuccessful && isSessionConfirmed
                        }
                    >
                        {!isSessionConfirmed
                            ? t("participantSide.swapSessionsScreen.swapModal.submit")
                            : t("participantSide.swapSessionsScreen.swapModal.confirmedSubmit")}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export {
    SwapSessionConfirmSwapModal,
    type TSwapSessionConfirmSwapModalProps,
    type TSwapSessionConfirmSwapModalSubmitFunction
}
