import type { FC, MouseEvent, ReactElement } from "react"

import { DateTime } from "luxon"
import { Link } from "react-router-dom"

import { Button } from "@/3514/components"
import { Session } from "@/models/session"

import { CoachTableGeneralCell } from "./CoachTableGeneralCell"

type TCoachTableJoinSessionCellProps = { session: Session }

const classes = {
    link: "block w-max",
    button: [
        "group",
        "!bg-accentOld",
        "hover:!bg-white",
        "focus:!bg-white",
        "hover:!border-accentOld",
        "focus:!border-accentOld",
        "hover:border-[1px]",
        "focus:border-[1px]",
        "table-btn",
        "w-auto"
    ].join(" "),
    buttonText: [
        "font-[800]",
        "transition-all",
        "duration-200",
        "group-hover:!text-black",
        "group-focus:!text-black",
        "text-white",
        "font-[300]"
    ].join(" ")
}

const CoachTableJoinSessionCell: FC<TCoachTableJoinSessionCellProps> = ({
    session
}: TCoachTableJoinSessionCellProps): ReactElement => {
    const isSessionAvailable: boolean = ((): boolean => {
        const currentTime: DateTime = DateTime.now()
        const sessionTime: DateTime = DateTime.fromISO(session.sessionTime)
        const sessionAvailableTime: DateTime = sessionTime.minus({ minutes: 15 })
        const sessionEndTime: DateTime = sessionTime.plus({ minutes: session.sessionType.duration })

        return (
            currentTime.toMillis() >= sessionAvailableTime.toMillis() &&
            currentTime.toMillis() < sessionEndTime.toMillis() &&
            !!session?.joinUrl
        )
    })()

    return (
        <CoachTableGeneralCell isLarge isHidden withoutLeftAlignedText withoutLeftPadding>
            {isSessionAvailable ? (
                <Link
                    onClick={(e: MouseEvent<HTMLAnchorElement>): void => e?.stopPropagation?.()}
                    to={session?.joinUrl}
                    target="_blank"
                    className={classes.link}
                    rel="noreferrer"
                >
                    <Button className={classes.button}>
                        <span className={classes.buttonText}>Join Session</span>
                    </Button>
                </Link>
            ) : null}
        </CoachTableGeneralCell>
    )
}

export { CoachTableJoinSessionCell, type TCoachTableJoinSessionCellProps }
