import { FC, ReactNode } from "react"

import type { IParticipantModel } from "@/adapters"
import "@/assets/onboarding.scss"
import { type IAuthContext, useAuthContext } from "@/context"
import UserHeaderDropdown from "@/elements/participant-layout-header/UserHeaderDropdown"
import { ViewAsBanner } from "@/layouts/components"
import ProductTypeLogo from "@/shared/ProductTypeLogo"

import OnBoardingContainer from "../OnBoardingContainer"
import { useOnboardingStoreContext } from "../onboardingStore"

interface IProps {
    header?: ReactNode
    pageTitle?: ReactNode
    children?: ReactNode
}

const OnboardingLayout: FC<IProps> = ({ children, header = null, pageTitle = null }) => {
    const { logout, user }: IAuthContext = useAuthContext()
    const { setStoreData } = useOnboardingStoreContext()

    const handleLogout = async e => {
        e.preventDefault()

        logout({ keepLocalStorageAuthTokens: (user as IParticipantModel)?.isFakeParticipant })
        setStoreData({})
    }

    return (
        <div className="wrapper-onboarding flex-column">
            {(user as IParticipantModel)?.isFakeParticipant && <ViewAsBanner />}
            <header className="header-sticky">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <ProductTypeLogo width={26} heigth={34} />
                        <span className="header-center mx-auto order-3 order-md-2 mt-4 mt-md-0">{header}</span>
                        <div className="order-2 order-md-3">
                            <UserHeaderDropdown
                                className="md:!top-[18px] md:!right-[10px]"
                                handleLogout={handleLogout}
                                user={user}
                                showOnMobile
                            />
                        </div>
                    </nav>
                </div>
            </header>
            <main className="main w-100">
                <div className="container">
                    {pageTitle && (
                        <div className="page-title">
                            <h1 className="h1 font-extrabold text-center mb-6">{pageTitle}</h1>
                        </div>
                    )}
                    <OnBoardingContainer>{children}</OnBoardingContainer>
                </div>

                <footer className="footer-onboarding" />
            </main>
        </div>
    )
}

export default OnboardingLayout
