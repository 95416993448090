import type { FC, ReactElement } from "react"

import ReactTooltip from "react-tooltip"

import { THEME } from "@/3514/config"
import { type IUseTranslation, useTranslation } from "@/hooks"

import { ETypographyAlignment, ETypographySize, ETypographyTag, Typography } from "../typography"

import { verticalProgressBarConfig as config } from "./vertical-progress-bar.config"
import {
    EVerticalProgressBarFillLevel as ELevel,
    EVerticalProgressBarTooltipPlacement as EPlacement
} from "./vertical-progress-bar.types"

type TProps = { barLevel: ELevel; placement: EPlacement; barId: string }

const classes: { tooltip(): string; typography(): string } = {
    tooltip: (): string => "!rounded-[5px] !opacity-[0.98] !border-[1px] after:!hidden before:!hidden !p-[0px]",
    typography: (): string => "px-[7px] py-[5px]"
}

const Component: FC<TProps> = ({ barLevel, placement, barId }: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <ReactTooltip
            id={barId}
            className={classes.tooltip()}
            arrowColor="transparent"
            border
            borderColor={THEME.COLORS.gray[400]}
            backgroundColor={THEME.COLORS.white}
            effect="solid"
            place={placement === EPlacement.Center ? "bottom" : "left"}
            {...(placement === EPlacement.Center && { offset: { top: 110 } })}
        >
            <Typography
                className={classes.typography()}
                text={t(config.defaultTooltipConfig[barLevel])}
                alignment={ETypographyAlignment.Center}
                size={ETypographySize.Small}
                tag={ETypographyTag.Paragraph}
            />
        </ReactTooltip>
    )
}

Component.displayName = "VerticalProgressBarTooltip"

export { Component as VerticalProgressBarTooltip, type TProps as TVerticalProgressBarTooltipProps }
