import { type ForwardedRef, type NamedExoticComponent, type ReactElement, type ReactNode, forwardRef } from "react"

import classNames from "classnames"

import { spinnerConfig } from "./spinner.config"
import { ESpinnerSize, ESpinnerVariant } from "./spinner.types"

type TProps = {
    size?: ESpinnerSize
    variant?: ESpinnerVariant
    className?: string
    wrapperClassName?: string
    renderLogo?(): ReactNode
}

const { sizesMap, colorsMap } = spinnerConfig

/**
 * Renewed spinner component
 * @param size ESpinnerSize
 * @param variant ESpinnerVariant
 * @param className string or undefined
 * @param wrapperClassName string or undefined
 * @param withLogo boolean parameter indicating presence of logo
 * @return {ReactElement}
 */
const Component: NamedExoticComponent<TProps> = forwardRef<TProps, HTMLDivElement>(
    (
        {
            size = ESpinnerSize.Medium,
            variant = ESpinnerVariant.Accent,
            className = String(),
            wrapperClassName = String(),
            renderLogo = undefined
        }: TProps,
        ref?: ForwardedRef<HTMLDivElement>
    ): ReactElement => (
        <div
            ref={ref}
            className={classNames("flex flex-col items-center justify-center h-min", {
                [wrapperClassName]: wrapperClassName
            })}
        >
            {renderLogo?.()}
            <i
                className={classNames("border-[3px] !border-t-transparent rounded-full animate-spin", className, {
                    [sizesMap[size]]: size,
                    [colorsMap[variant]]: variant
                })}
            />
        </div>
    )
)

Component.displayName = "Spinner"

export { Component as Spinner, type TProps as TSpinnerProps }
