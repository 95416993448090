import { useRef, useState } from "react"

import classNames from "classnames"
import { Link, matchPath, useLocation } from "react-router-dom"

import { useOnClickOutside } from "$/hooks/use-outside-click"

import { Button, EButtonSize, EButtonVariant } from "@/3514/components"
import type { IParticipantModel } from "@/adapters"
import { StepUrls as OnboardingUrls, onboardingProfileSetupURL } from "@/components/onboarding/constants"
import { ParticipantUrls } from "@/constants"
import { type IUseTranslation, useTranslation } from "@/hooks"
import User from "@/models/user"
import Avatar from "@/shared/avatar/Avatar"
import { HeaderDropdownProfileList } from "@/shared/header-dropdown-profile-list"
import useRoles from "@/utils/hooks/use-roles"

import "./styles.scss"

type Props = {
    handleLogout: (e) => void
    showOnMobile?: boolean
    user: User
    className?: string
}

const UserHeaderDropdown = ({ user, showOnMobile = false, className = "", handleLogout }: Props) => {
    const { t }: IUseTranslation = useTranslation()
    const location = useLocation()
    const { isCoaches } = useRoles()
    const [isActiveMenu, setIsActiveMenu] = useState(false)
    const outsideRefClick = useRef<HTMLDivElement>()

    const toggleActiveMenu = () => setIsActiveMenu(prev => !prev)

    const showProfile =
        !Object.values({
            ...OnboardingUrls,
            onboardingProfileSetupURL,
            profile: ParticipantUrls.PROFILE,
            assignModules: ParticipantUrls.ASSIGN_MODULE
        }).some(path => matchPath(path, location.pathname)) && !isCoaches

    useOnClickOutside(outsideRefClick, (): void => setIsActiveMenu(false))

    return (
        <div
            className={classNames(
                "d-md-block user-header-dropdown",
                {
                    "d-none": !showOnMobile,
                    "fake-mode": (user as IParticipantModel)?.isFakeParticipant,
                    "!top-[10px] !right-[15px]": location.pathname.includes("_dashboard")
                },
                className
            )}
            ref={outsideRefClick}
        >
            <div className="dropdown header-nav-dropdown">
                <Button
                    className="flex items-center justify-center w-full max-w-[35px] h-[35px] border-[1px] border-transparent hover:!border-gray-400 focus:!border-gray-400"
                    variant={EButtonVariant.EmptyTransparent}
                    size={EButtonSize.Container}
                    id="userDropdownButton"
                    data-testid="userDropdownButton"
                    data-toggle="dropdown"
                    aria-expanded={isActiveMenu}
                    aria-label={t("participantSide.header.dropdown.opener.ariaLabel")}
                    onClick={toggleActiveMenu}
                >
                    <Avatar
                        width={25}
                        height={25}
                        imgClassName="h-full"
                        url={user?.photo}
                        alt={t("common.avatarFallback.alt")}
                    />
                </Button>
            </div>
            <div className={classNames("dropdown-header-menu", { show: isActiveMenu })}>
                {showProfile && (
                    <Link className="dropdown-header-item" to={ParticipantUrls.PROFILE}>
                        {t("My Profile")}
                    </Link>
                )}
                <Link className="dropdown-header-item" to="#" onClick={handleLogout}>
                    {t("Sign Out")}
                </Link>
                {(user as IParticipantModel)?.isFakeParticipant && <HeaderDropdownProfileList />}
            </div>
        </div>
    )
}

export default UserHeaderDropdown
