import { useCallback, useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { FormProvider, useForm } from "react-hook-form"
import { type Location, useLocation, useParams } from "react-router-dom"

import useQueryParam from "$/hooks/use-query-param"
import { getErrorMessages } from "$/utils/get-error-messages"

import { Button } from "@/3514/components"
import { WarningMessage } from "@/components/onboarding/components/WarningMessage"
import { ServerStateKeys } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import { useRespondentSurvey, useRespondentSurveySubmit } from "@/entities/survey"
import { SurveySubmitData } from "@/entities/survey/models"
import { type IUseTranslation, useTranslation } from "@/hooks"
import { EStorageServiceType, LocalStorageServiceKeys, StorageService } from "@/services"
import CareTeamLink from "@/shared/CareTeamLink"
import ErrorTooltip from "@/shared/error-page/ErrorTooltip"
import { FullSpinner } from "@/shared/spinner"
import OwlErrorLogo from "@/svgs/OwlErrorLogo"

import { createValidationSchema } from "../validation"

import { FeedbackSurvey } from "./FeedbackSurvey"
import { Survey } from "./Survey"

import "./styles.scss"

const storageService: StorageService = new StorageService(EStorageServiceType.Local)

export const StakeHolderSurvey = () => {
    const { t }: IUseTranslation = useTranslation()
    const query = useQueryParam()
    const params = useParams()
    const accessCode = params.access_code || query.get("access_code")
    const [error, setError] = useState(null)
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error: surveyError,
        refetch
    } = useRespondentSurvey(accessCode)
    const submitSurvey = useRespondentSurveySubmit(accessCode)
    const [surveySubmitLoading, setSurveySubmitLoading] = useState(false)

    const location: Location = useLocation()

    const queryClient: QueryClient = useQueryClient()

    const { refetchUser }: IAuthContext = useAuthContext()

    const methods = useForm({
        shouldFocusError: true,
        resolver: data?.questions && isSuccess ? yupResolver(createValidationSchema(data.questions)) : undefined
    })

    useEffect(() => {
        if (data && isSuccess && !data.message) {
            methods.reset(
                data.questions.reduce((acc, question) => {
                    return {
                        [question.id]: {
                            free_response: null,
                            answer: null
                        }
                    }
                }, {})
            )
        }
    }, [data, isSuccess])

    const { handleSubmit } = methods

    const submitFeedback = useCallback(
        values => {
            const submitData: SurveySubmitData[] = Object.entries(values).map(
                ([key, value]: [key: string, value: { answer: string | number; free_response: string | null }]) => ({
                    question: +key,
                    answer: value?.answer ? +value.answer : null,
                    free_response: value?.free_response || null
                })
            )
            setSurveySubmitLoading(true)
            submitSurvey.mutate(
                { response: submitData },
                {
                    onSuccess: () => {
                        refetch().finally(() => {
                            setSurveySubmitLoading(false)
                        })

                        if (location?.state?.shouldBackButtonRedirectToSurveysPage) {
                            queryClient.refetchQueries({ queryKey: [ServerStateKeys.Surveys] })
                            refetchUser()?.then(response => {
                                if (
                                    response?.data?.hasNotCompletedSurveys === false &&
                                    storageService.getItem(
                                        LocalStorageServiceKeys.SurveysPageRedirected(response?.data?.id)
                                    )
                                ) {
                                    storageService.removeItem(
                                        LocalStorageServiceKeys.SurveysPageRedirected(response.data.id)
                                    )
                                }
                            })
                        }
                    },
                    onError: (error: AxiosError): void => setError(getErrorMessages(error))
                }
            )
        },
        [queryClient, submitSurvey, refetch, refetchUser, location]
    )

    if ((isLoading || isFetching || surveySubmitLoading) && !isSuccess) {
        return <FullSpinner />
    }

    if (isError && surveyError) {
        return (
            <div className="d-flex flex-column align-items-center">
                <ErrorTooltip>
                    <p className="text-center m-0">{getErrorMessages(surveyError).join("")}</p>
                </ErrorTooltip>
                <div className="mt-5 d-flex justify-content-center">
                    <OwlErrorLogo />
                </div>
                <CareTeamLink variant="brand" text="Need help?" className="care-link" />
            </div>
        )
    }

    return (
        <main className="stakeholder-survey">
            <FormProvider {...methods}>
                {data.message ? (
                    <FeedbackSurvey status={data.message} isSelfAssessment={data?.isSelfAssessment} />
                ) : (
                    <Survey survey={data} />
                )}
            </FormProvider>
            {!data.message && (
                <>
                    <WarningMessage message={error} />
                    <div className="mx-auto w-max">
                        <Button onClick={handleSubmit(submitFeedback)}>{t("Submit")}</Button>
                    </div>
                </>
            )}
        </main>
    )
}
