import { FC, useMemo } from "react"

import classNames from "classnames"

import { type IAuthContext, useAuthContext } from "@/context"
import { isParticipant } from "@/utils/gates/user"

import "./styles.scss"

interface IProps {
    max: number
    current: number
    title: string
    onClick?: () => void
    active?: boolean
    summaryValue: string
    className?: string
}

const ResultsBar: FC<IProps> = ({ max, current, title, onClick, active = false, className = "", summaryValue }) => {
    const computedWidth = useMemo(() => {
        return Math.round((current / max) * 100)
    }, [max, current])

    const { user }: IAuthContext = useAuthContext()

    const isCoachSide: boolean = !isParticipant(user)

    return (
        <div className={classNames("result-bar", { [className]: className })} onClick={onClick}>
            <span className={classNames("result-bar__title", { "text-white": active })}>
                {title} - {summaryValue}
            </span>
            <span
                className={classNames("result-bar__progress", active && isCoachSide && "!bg-accentOld", {
                    active,
                    "is-full": computedWidth >= 100
                })}
                style={{ width: `${computedWidth}%` }}
            />
        </div>
    )
}

export default ResultsBar
