import classNames from "classnames"

import { Link } from "@/3514/components"

type Props = {
    className?: string
    withoutPrivacyPolicyLink?: boolean
}

export const CopywriteText = ({ className = "", withoutPrivacyPolicyLink = false }: Props) => {
    return (
        <p className={classNames("color-gray fs-14 mb-0", className)}>
            © {new Date().getFullYear()} GetWiser, Inc. All Rights Reserved
            {!withoutPrivacyPolicyLink && (
                <>
                    <svg
                        width="2"
                        height="19"
                        viewBox="0 0 2 19"
                        className="ml-2 mr-2 d-none d-md-inline"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 0V19" stroke="#6F6F6F" strokeWidth="0.5" />
                    </svg>
                    <Link to="/privacy" isTargetBlank rel="noopener noreferrer">
                        Privacy Policy
                    </Link>
                </>
            )}
        </p>
    )
}
